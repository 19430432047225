import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { LeadstaskComponent } from '../leads-task/leads-task.component';
import { UtilsService } from '../../core/utils.service';
import { LoginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss']
})
export class LeadsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public leadsFilters: any = {};
  public hasPermission: any;

  constructor(
    private contactsService: ContactsService,
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private utilsService: UtilsService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }
  viewLead(lead) {
    this.router.navigate(["/leads/" + + (lead ? lead.id : 0)]);

  }
  deleteLead(lead) {
    if (this.loginService.hasPermission("LEADS_DELETE")) {
      this.coreDialogService.confirm({
        message: this.translateService.instant("component.leads.delete_message", { name: lead.name }),
        header: this.translateService.instant("general.confirmation_delete_title"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.contactsService.delete(lead.id).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
              this.router.navigate(['/leads']);
              this.view.fireEvent("leads.list.results", "reload");
              this.utilsService.alertsChange.emit(true);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          });
        },
        reject: () => { }
      });
    }
  }
  createTaskAndAddAsContact(selectedContacts: any) {
    if (this.loginService.hasPermission("LEADS_WRITE")) {
      const ref = this.dialogService.open(LeadstaskComponent, {
        data: {
          contactsIds: selectedContacts,
        },
        header: this.translateService.instant("component.leads.process_leads"),
        width: '60%'
      });
      ref.onClose.subscribe((data: any) => {
        this.view.fireEvent("leads.list.results", "reload");
        this.utilsService.alertsChange.emit(true);
        selectedContacts = [];
      });
    }
  }
  deleteMultiple(selectedContacts: any) {
    if (this.loginService.hasPermission("LEADS_DELETE")) {
      this.coreDialogService.confirm({
        message: this.translateService.instant("component.leads.delete_elements"),
        header: this.translateService.instant("general.confirmation_delete_title"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.contactsService.deleteMultiple(selectedContacts).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.leads.success_delete_elements") });
              // this.router.navigate(['/leads']);
              this.view.fireEvent("leads.list.results", "reload");
              this.utilsService.alertsChange.emit(true);
            },
            error: (error: any) => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          })
        },
        reject: () => { }
      });
    }
  }
  onEvent(event: any) {
    if (event.component == "leads.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("leads.list.results", "reload");
    } else if (event.component == "leads.list.results") {
      if (event.event == "dblclick") this.viewLead(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewLead(null);
      if (event.event == "action" && event.action.name == "edit") this.viewLead(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteLead(event.data);
      if (event.event == "action" && (event.action.name == "edit_multiple" || event.action.name == "delete_multiple")) {
        if (event.selectedRow.length == 0) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.leads.error_no_selected_lead"),
            header: this.translateService.instant("general.error"),
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          if (event.action.name == "edit_multiple") {
            this.createTaskAndAddAsContact(event.selectedRow);
          } else {
            this.deleteMultiple(event.selectedRow);
          }
        }
      }
    }
  }
}
