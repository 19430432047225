import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../core/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignsContactsService {
  url = "/api/campaignsContacts";

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  all(params): Observable<any> {
    if (params == null) params = {};
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }

  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }

  addMultiples(obj): Observable<any> {
    return this.http.post<any>(this.url + "/addMultiples", obj);
  }

  unlinkContact(obj): Observable<any> {
    return this.http.post<any>(this.url + "/unlinkContact", obj);
  }
  addMultiplesCampaignsToContact(obj): Observable<any> {
    return this.http.post<any>(this.url + "/addMultiplesCampaignsToContact", obj);
  }
}
