import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { TasksStatusService } from '../../tasks/tasks-status.service';
import { TasksService } from '../../tasks/tasks.service';
import { CompaniesIntegrationsService } from '../../configuration/integration/companiesintegrations.service';
import { TasksActionTypesService } from '../../tasks/tasks-action-types.service';
import { UsersService } from '../../users/users.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { LoginService } from '../../login/login.service';
import { ZonesService } from 'src/app/configuration/zones/zones.service';
import { TranslateService } from '@ngx-translate/core';
import { ProcessLeadsMessageErrorComponent } from '../process-leads-message-error/process-leads-message-error.component';

@Component({
  selector: 'app-leads-task',
  templateUrl: './leads-task.component.html',
  styleUrls: ['./leads-task.component.scss']
})
export class LeadstaskComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public leadsFilters: any = {};
  public contactsIds: any;
  public task: any = {};
  public task_actions: any[] = [];
  public responsibles: any[] = [];
  public task_status: any[] = []
  public organizations: any[];
  public typeProcessOptions: any[] = [];
  public selectedOrganizationId: number = null;
  public hasPermission: any;
  public hasZonesRead: any;
  //public hasZonesWrite: any;
  public selectedOrganization: any;
  public zones: any[] = [];
  public filterValue = "";
  public searchResults: any[] = [];
  public searchText: string = "";

  constructor(
    private contactsService: ContactsService,
    private zonesService: ZonesService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private dynamicDialogRef: DynamicDialogRef,
    private tasksStatusService: TasksStatusService,
    private taskService: TasksService,
    private config: DynamicDialogConfig,
    private companiesIntegrationsService: CompaniesIntegrationsService,
    private taskActionsTypesService: TasksActionTypesService,
    private responsiblesService: UsersService,
    private organizationService: OrganizationsService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private routerLink: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.hasPermission = this.loginService.hasPermission("LEADS_WRITE");
    this.hasZonesRead = this.loginService.hasPermission("ZONES_READ") || this.loginService.hasPermission("ZONES_SUPERADMIN");
    //this.hasZonesWrite = this.loginService.hasPermission("ZONES_WRITE") || this.loginService.hasPermission("ZONES_SUPERADMIN");
    this.loadTaskCombos();
    this.loadZones();

    this.contactsIds = this.config.data.contactsIds;
    var now = new Date();
    var nowMultiple = now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
    this.task.date = new Date(nowMultiple);
    this.task.task_action_scheduled_date = new Date(nowMultiple);
    this.companiesIntegrationsService.getByCode("webform").subscribe({
      next: (data: any) => {
        if (data != null) {
          if (data.configuration == null) data.configuration = {};
          if (data.configuration.task_action_id != null && data.configuration.task_action_id != "") {
            this.task.task_action_id = data.configuration.task_action_id;
          }
          if (data.configuration.subject != null && data.configuration.subject != "") {
            this.task.subject = data.configuration.subject;
          }

          if (data.configuration.responsible_id != null && data.configuration.responsible_id != "") {
            this.task.responsible_user_id = data.configuration.responsible_id;
          }
          if (data.configuration.task_status_id != null && data.configuration.task_status_id != "") {
            this.task.task_status_id = data.configuration.task_status_id;
          }

          if (data.configuration.organization_id != null && data.configuration.organization_id != "") {
            this.task.organization_id = data.configuration.organization_id;
            this.selectedOrganizationId = data.configuration.organization_id;
          }

          if (data.configuration.typeProcessOptions != null && data.configuration.typeProcessOptions != "") {
            this.task.type_process_organization = data.configuration.typeProcessOptions;
          }

          this.loadOrganizations(null);
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }
  loadTaskCombos() {
    this.taskActionsTypesService.all({}).subscribe({
      next: (data: any) => {
        this.task_actions = data.rows;
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.responsiblesService.combo({}).subscribe({
      next: (data: any) => {
        this.responsibles = data.rows;
        this.responsibles.unshift({ name: this.translateService.instant("general.undefined"), id: null });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.tasksStatusService.all({}).subscribe({
      next: (data: any) => {
        this.task_status = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });

    this.typeProcessOptions = [
      { code: "assign", name: this.translateService.instant("component.leads.type_process_assign_name"), description: this.translateService.instant("component.leads.type_process_assign_description") },
      { code: "create", name: this.translateService.instant("component.leads.type_process_create_name"), description: this.translateService.instant("component.leads.type_process_create_description") }
    ];

  }
  getInfoTypeProcessOptions() {
    let organizationName = this.translateService.instant("general.selected");
    if (this.task.organization_id != null) {
      if (typeof (this.organizations) != "undefined") {
        var aux = this.organizations.filter((m: any) => m.id == this.task.organization_id);
        organizationName = aux.length > 0 ? aux[0].name : this.translateService.instant("general.selected");
        this.selectedOrganization = aux.length > 0 ? aux[0] : [];
      }
    }
    var message = this.typeProcessOptions.filter((m: any) => m.code == this.task.type_process_organization).map((m: any) => m.description)[0];
    return message.replace("[ORGANIZATION]", organizationName);
  }
  loadOrganizations(event) {
    let params: any = {};
    if (event != null) params.text = event.query; //Viene desde el autocomplete
    if (this.filterValue != null && this.filterValue != "") params.text = this.filterValue;
    if (this.selectedOrganizationId != null) params.include_organization_id = this.selectedOrganizationId;

    if (this.filterValue.length >= 3 || this.filterValue.length == 0) {
      this.organizationService.getCombo(params).subscribe({
        next: (data: any) => {
          this.organizations = data.rows;
          this.searchResults = [...data.rows]; 
          this.organizations.unshift({ name: this.translateService.instant("general.select"), id: null });
          if (this.selectedOrganizationId != null) {
            this.selectedOrganization = this.organizations.find(m => m.id == this.selectedOrganizationId);
          }
        },
        error: (error) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
      });
    }
  }



  loadZones() {
    this.zonesService.combo({}).subscribe({
      next: (data: any) => {
        this.zones = data.rows;
        this.zones.unshift({ name: this.translateService.instant("general.undefined"), id: null });
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }

  saveTask() {
    this.contactsService.addContactMultipleAndCreateTask(this.contactsIds, this.task).subscribe({
      next: (data) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.leads.task_and_contacts_created") });
        this.dynamicDialogRef.close();
        this.view.fireEvent("leads.list.results", "reload");
      },
      error: (error) => {

        if (error.error.title.includes("component.leads_task.error_process_by_type")) {
          //Si se cumple la condición, significa que ha encontrado un contacto que ya fue procesado anteriormente.
          var parts = error.error.title.split(":");
          var processedContacts = parts[2];
          processedContacts = processedContacts.split("|"); // Lista de contactos que ya fueron procesados.

          var message_error = this.translateService.instant(parts[0], { total_processed: parts[1], encountered_contact: parts[2] });
          this.messageService.add({ closable: false, severity: "error", detail: message_error });
          this.open_message_error(message_error, processedContacts);
          this.dynamicDialogRef.close();
          this.routerLink.navigate(['/leads']);
        }
        else {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      }
    });

  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  changeOrg($event) {
    if ($event != null) this.task.organization_id = $event.id;
    else {
      this.selectedOrganization = null;
      this.task.organization_id = null;
    };
  }

  open_message_error(message_error: string, processed_contacts = []) {
    const ref = this.dialogService.open(ProcessLeadsMessageErrorComponent, {
      data: {
        processed_contacts: processed_contacts,
        message_error: message_error
      },
      header: this.translateService.instant("component.leads.process_leads"),
      width: '60%'
    });
    ref.onClose.subscribe((data: any) => {

    });
  }

}
