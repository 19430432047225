import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewsService } from '../../views.service';
import groupBy from 'lodash/groupBy';
import { LoginService } from '../../../../login/login.service';

@Component({
  selector: 'app-view-component-add',
  templateUrl: 'view-component-add.component.html',
  styleUrls: ['view-component-add.component.scss'],
})
export class ViewComponentAddComponent implements OnInit {

  public components: any[] = [];

  constructor(
    private viewsService:ViewsService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private loginService: LoginService
  ) { 
  }

  ngOnInit(): void {
    this.loadComponents();
  }
  
  loadComponents() {
    this.viewsService.getViewComponents(this.config.data.view_code).subscribe({
      next: (data:any) => {
        //this.components = data;
        data.forEach((item: any) => {
          if (item.base_component) {
            if (item.base_component.configuration?.ngShow) {
              var aux = eval(item.base_component.configuration?.ngShow);
              //Si cumple la condición, se muestra, sino la cumple, no
              if (aux) {
                this.components.push(item);
              } else {
                console.log("El componente " + item.base_code?.code + " no cumple las condiciones del ngShow para mostrarlo como opción.");
              }
            } else {
              this.components.push(item);
            }
          } else {
            this.components.push(item);
          }
        })
    },
      error:(error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  selectComponent(component){
    this.dynamicDialogRef.close(component);
  }

}
