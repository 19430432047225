<form #formDetails="ngForm" id="reportMailMarketing">
  <p-card styleClass="p-card p-component">

    <div class="p-grid  p-grid-nogutter">
      <div class="p-col-12 p-field p-mt-3">
        <label for="name">
          {{ 'component.reports.detail.records_to_send_email' | translate: { numRegisters: this.selected.length, totalResults: prevTotalRows } }}<br />
        </label>
        <p-table #grid [lazy]="true" [value]="rows" [columns]="results.labels" [paginator]="false"
          [totalRecords]="results.length" [reorderableColumns]="false" styleClass="p-datatable-sm" [scrollable]="true"
          scrollHeight="150px" class="p-mt-3" *ngIf="prevTotalRows <= 500">

          <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
            <tr [pReorderableRow]="index">
              <td width="25"><p-checkbox name="groupname" value="{{item.base_id}}" [inputId]="item.base_id"
                  [(ngModel)]="selected"></p-checkbox></td>
              <td *ngFor="let header of results.labels"
                [innerHTML]="getRowValue(header, item[header.column_key], item )">
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="results.values.length + 1">
                {{'general.not_data' | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="p-card">
      <div class="p-grid p-col-12">
        <div class="p-col-12 p-field">
          <label for="template">{{'component.reports.detail.templates' | translate}}</label>
          <p-dropdown name="template" [(ngModel)]="email.template_id" [options]="templates" optionLabel="name"
            (onChange)="loadTemplateValue($event)" optionValue="id" [showClear]="true" appendTo="body">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-field">
          <label for="campaigns">{{'component.reports.detail.campaigns' | translate}}</label>
          <p-dropdown name="campaigns" [(ngModel)]="email.campaign_id" [options]="campaigns" optionLabel="name"
            optionValue="id" [showClear]="true" appendTo="body">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-field">
          <label for="from">{{'component.reports.detail.from' | translate}}</label>
          <p-dropdown name="from" [(ngModel)]="email.mail_account_id" [options]="emails" optionValue="mailAccount.id"
            optionLabel="mailAccount.name" placeholder="{{'component.reports.detail.select_email' | translate}}"
            (onChange)="onMailAccountChange()"></p-dropdown>
          <div
            *ngIf="selectedMailAccount!=null && selectedMailAccount?.mailAccount?.type!='mailchimp' && selectedMailAccount?.mailAccount?.type!='mailjet'">
            <p-messages severity="warn" class="p-d-block">
              <ng-template pTemplate>
                {{'component.reports.detail.message1' | translate}}<br>
                {{'component.reports.detail.message2' | translate}}
              </ng-template>
            </p-messages>
          </div>
        </div>
        <div class="p-col-12 p-field">
          <label for="subject">{{'component.reports.detail.subject' | translate}}</label>
          <input name="subject" pInputText type="text" [(ngModel)]="email.subject">
        </div>
        <div class="p-col-12 p-field">
          <label for="message">{{'component.reports.detail.message' | translate}}</label>
          <editor name="message" [(ngModel)]="email.body" 
            cloudChannel="5" [init]="tinyMceConfig" plugins="link media image fullpage code table quickbars"></editor>
        </div>

        <div class="p-col-12 p-field" style="position:relative">
          <label for="myfile[]">{{'component.reports.detail.attachments' | translate}}</label>
          <p-fileUpload name="myfile[]" [showUploadButton]="false" url="/api/attachments/upload"
            (onError)="onErrorUpload($event)" (onUpload)="onBasicUpload($event)" multiple="multiple" [auto]="true"
            chooseLabel="{{'component.reports.detail.upload_attachments' | translate}}">
            <ng-template pTemplate="content">
              <ul *ngIf="email.files.length">
                <li *ngFor="let file of email.files; let i = index">
                  <a (click)="deleteAttachment(file.guid, i)"><i class="pi pi-times-circle "
                      style="font-size: 12px;margin-right: 10px;"></i></a>
                  {{file.filename}} - {{file.size}} bytes
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>

          <p-button label="{{'component.reports.detail.select_from_axial' | translate}}" icon="pi pi-plus"
            (click)="uploadAxial()" style="position: absolute;top: 41px;left: 175px;"></p-button>
        </div>
      </div>
    </div>
  </p-card>

  <div class="p-d-flex p-mt-3">
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
      styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
    <p-button label="{{'component.reports.detail.save_mailmarketing' | translate}}" icon="pi pi-check"
      styleClass="p-ml-2" (click)="sendMails()"></p-button>
  </div>
</form>
