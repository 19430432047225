<p-card styleClass="p-mb-4">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'product.entity.name' | translate}}
      <div class="p-card-title-actions">
        <a (click)="clearFilters()">
          <!--fa-icon [icon]="['fas', 'plus-circle']"></fa-icon-->Limpiar ficha
        </a>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-3 p-field" *ngIf="is_available('family_id')">
      <label for="families">{{'product.entity_fields.family_id' | translate}}</label>
      <p-dropdown name="families" [options]="familiesFiltered" [(ngModel)]="params.family_id"
        placeholder="{{'component.transaction.detail.select_family' | translate}}" optionValue="id" optionLabel="name"
        [showClear]="true" filterMatchMode="hasAllWords"
        (onChange)="onDropDownChange('family_id', $event, $event.value)" appendTo="body" [filter]="true"></p-dropdown>
    </div>
    <div class="p-col-3 p-field" *ngIf="is_available('product_id')">
      <label for="products">
        {{'product.entity.name' | translate}}
      </label>
      <p-dropdown name="products" [options]="productsNamesJson" [(ngModel)]="params.product_name" optionValue="name"
        optionLabel="name" placeholder="{{'component.transaction.detail.select_product' | translate}}"
        [showClear]="true" (onChange)="onDropDownChange('product_name', $event, $event.value)"
        filterMatchMode="hasAllWords" appendTo="body" [filter]="true"></p-dropdown>
    </div>
    <div class="p-col-3 p-field" *ngIf="hasCharacteristics && is_available('characteristics1')">
      <label name="characteristics1" for="characteristics1">{{characteristics1Name}}</label>
      <p-dropdown [options]="characteristics1" [(ngModel)]="params.characteristics1" [filter]="true" optionLabel="label"
        optionValue="value" placeholder="{{'component.transaction.detail.select_characteristic1' | translate}}"
        [showClear]="true" filterMatchMode="hasAllWords"
        (onChange)="onDropDownChange('characteristics1', $event, $event.value)" appendTo="body">
      </p-dropdown>
    </div>
    <div class="p-col-3 p-field" *ngIf="hasCharacteristics && is_available('characteristics2')">
      <label for="characteristics2">{{characteristics2Name}}</label>
      <p-dropdown name="characteristics2" [options]="characteristics2" [(ngModel)]="params.characteristics2"
        optionLabel="label" optionValue="value" [filter]="true"
        placeholder="{{'component.transaction.detail.select_characteristic2' | translate}}" [showClear]="true"
        filterMatchMode="hasAllWords" (onChange)="onDropDownChange('characteristics2', $event, $event.value)"
        appendTo="body">
      </p-dropdown>
    </div>
  </div>
</p-card>