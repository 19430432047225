import { Component, NgZone } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgEventBus } from 'ng-event-bus';
import { MetaData } from 'ng-event-bus/lib/meta-data';
import { PrimeNGConfig } from 'primeng/api';
import { slideInAnimation } from './animations';
import { ConfigurationService } from './configuration/configuration.service';
import { EntitiesService } from './configuration/entities.service';
import { CustomHistoryService } from './core/customHistory.service';
import { ViewsService } from './core/view/views.service';
import { LoginService } from './login/login.service';
import { OpportunitiesMonthsWidgetComponent } from './organizations/organizations-detail/opportunities-months-widget/opportunities-months-widget.component';
import { OpportunitiesTotalPipelineComponent } from './organizations/organizations-detail/opportunities-total-pipeline/opportunities-total-pipeline.component';
import { OpportunitiesWidgetComponent } from './organizations/organizations-detail/opportunities-widget/opportunities-widget.component';
import { SalesTotalPipelineComponent } from './organizations/organizations-detail/sales-total-pipeline/sales-total-pipeline.component';
import { TasksWidgetComponent } from './organizations/organizations-detail/tasks-widget/tasks-widget.component';
import { TasksHistoryLineComponent } from './tasks/tasks-history-line/tasks-history-line.component';
import { UserRolesComponent } from './users/users-detail/user-roles/user-roles.component';
import { VersionService } from './core/version.service';
import { DebugService } from './core/debug.service';
import { EntityHistoryLineComponent } from './entity-history-line/entity-history-line.component';
import { TransactionLinesDetailCustomComponent } from './transactions/transactions-detail/transactions-lines/transaction-lines-detail-custom/transaction-lines-detail-custom.component';
import { TranslateService } from '@ngx-translate/core';
import { TransactionSAGEInfoCustomComponent } from './transactions/transactions-detail/transactios-sage-info-custom/transactios-sage-info-custom.component';
import { CampaignsChartComponent } from './campaigns/campaigns-chart/campaigns-chart.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  public version:any = {};
  public name:string = "";
  public actualComponentName: string = "";
  public isLogged: boolean = false;
  public cssLoaded: boolean = false;
  public isOpen: boolean = true;
  public showTopbar:boolean = false;
  public showSidebar:boolean = false;

  constructor(
    private versionService:VersionService,
    private primengConfig: PrimeNGConfig,
    private loginService:LoginService,
    private viewsService: ViewsService,
    private configurationService: ConfigurationService,
    private eventBus: NgEventBus,
    private entitiesService: EntitiesService,
    private router: Router,
    private customHistoryService: CustomHistoryService,
    private ngZone: NgZone,  
    private debugService: DebugService,
    public translateService: TranslateService
  ) {

    this.getVersion();

    //registramos los componentes que pueden ser usados como custom en las views
    this.viewsService.RegisterCustomComponent("TasksWidgetComponent", TasksWidgetComponent);
    this.viewsService.RegisterCustomComponent("OpportunitiesWidgetComponent", OpportunitiesWidgetComponent);
    this.viewsService.RegisterCustomComponent("CampaignsChartComponent", CampaignsChartComponent);
    this.viewsService.RegisterCustomComponent("OpportunitiesMonthsWidgetComponent", OpportunitiesMonthsWidgetComponent);
    this.viewsService.RegisterCustomComponent("OpportunitiesTotalPipelineComponent", OpportunitiesTotalPipelineComponent);
    this.viewsService.RegisterCustomComponent("SalesTotalPipelineComponent", SalesTotalPipelineComponent);
    this.viewsService.RegisterCustomComponent("TasksHistoryLineComponent", TasksHistoryLineComponent);
    this.viewsService.RegisterCustomComponent("UserRolesComponent", UserRolesComponent);
    this.viewsService.RegisterCustomComponent("EntityHistoryLineComponent", EntityHistoryLineComponent);
    this.viewsService.RegisterCustomComponent("TransactionLinesDetailCustomComponent", TransactionLinesDetailCustomComponent);
    this.viewsService.RegisterCustomComponent("TransactionSAGEInfoCustomComponent", TransactionSAGEInfoCustomComponent);

    //cambiaos de estadod elogin
    this.loginService.onLoginStateChange.subscribe((state)=>{
      this.isLogged = state;
      if (this.isLogged) {
        //enviamos a preload con la ruta recibida, salvo que vayamos sesion cambiada y vayamos al login
        if (location.pathname != "/login-change" && location.pathname != "/login" && location.pathname != "/preload") {
          var url = location.pathname + location.search;
          this.router.navigateByUrl("/preload?returnUrl=" + encodeURIComponent(url));
        }
      }
    })
    this.loginService.checkLogged();

    //escuchamos eventos
    this.eventBus.on('sidebar:toogle').subscribe((meta: MetaData) => {
      this.isOpen = !this.isOpen;
    });

    //cargamos ultimo idioma selecciona o el guardado
    let current_lang = localStorage.getItem("language");
    if (current_lang == null || current_lang == "") {
      current_lang = "es";
      localStorage.setItem("language", current_lang);
    }
    this.translateService.setDefaultLang(current_lang);

  }
  

  getVersion(){
    this.versionService.get().subscribe(
      data => {
        this.version = data;
      }
    );
  }

  prepareRoute(outlet: RouterOutlet) {
    let componentName = "";
    if (outlet && outlet.isActivated) {
      let component:any = outlet.activatedRoute.component;
      componentName = component.name;
    }
    this.actualComponentName = componentName;
    return componentName;
  }

  onActivate(event) {

    //Vamos a guardar las urls por las que el usuario pasa. 
    if (!this.router.url.includes("/preload?") &&
        !this.router.url.includes("/page-not-found") &&
        !this.customHistoryService.checkIfExistPrev(this.router.url)) {
      this.customHistoryService.add(this.router.url);
    }

    //mostra topbar y sidebar si no es login
    if(this.router.url != "/login" && this.router.url != "/login-change"){
      this.showTopbar = true;
      this.showSidebar = true;
    }else{
      this.showTopbar = false;
      this.showSidebar = false;
    }

    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20);
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
  }

  configCssClass(){
    var cssClass = "";
    //cssClass += this.loginService.isLoggedIn()?"logged-in":"logged-out";
    cssClass += location.pathname == "/" || location.pathname == "/login" || location.pathname == "/login-change" || location.pathname == "/preload" ?"logged-out":"";
    return cssClass;
  }

  ngOnInit() {
    //añadimos funciones para llamar desde js (consola)
    window['angularComponentReference'] = { 
      component: this, 
      zone: this.ngZone, 
      enableDebug: () => this.debugService.enable(), 
      disableDebug: () => this.debugService.disable()
    };  


    //TRANSLATIONS:
    this.primengConfig.setTranslation({
        accept: 'Aceptar',
        reject: 'Cancelar',
        //translations calendar: 
        monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sá"],
        today: "Hoy",
        
    });

    
  }


}
