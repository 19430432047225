import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  alertsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private datePipe: DatePipe) { }
  
  clearObject(obj:any, allowedProperties:string[],): any {
    var finalObj = JSON.parse(JSON.stringify(obj));
    for (let [key, value] of Object.entries(finalObj)) {
      if(allowedProperties.indexOf(key)<0 && typeof value === 'object'){
        //es object y NO esta permitido incluirlo
        delete finalObj[key];
      }else if(typeof value === 'object'){
        //es object y esta permitido incluirlo
        if(Array.isArray(value)){
          for(let i = 0 ; i < value.length ; i++){
            finalObj[key][i] = this.clearObject(value[i], allowedProperties);
          }
        }else{
          finalObj[key] = this.clearObject(value, allowedProperties);
        }
      }
    }
    return finalObj;
  }

  randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-$!?+98/\|#*';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  formatDate(value: any, args: any[]) {
    return this.datePipe.transform(value, args[1]);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatWWW(value: any, args: any[]) {
    if (value == null) return "";
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    var formattedValue = "<a href='" + value + "' target='_blank'>" + value + "</a>";
    return formattedValue;
  }
  formatBadge(field: any, row: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
         // console.log(this, `Error evaluando this.model.${field.entityField.model_property}`);
        }
      }

      //si el campo tiene options->items, mapeamos valor
      if (field.entityField.configuration?.options?.items != null) {
        var item = field.entityField.configuration.options.items.find(m => m.code == value);
        if (item != null) value = item.text;
      }


      if (typeof (value) !== "undefined" && value != "" && value != null) {
        className = "p-badge p-component p-badge-no-gutter p-badge-xs  p-mr-2 " + subClassName;
        var formattedValue = "<div class='" + className + "' title='" + value + "'></div>" + value;
      } else {
        var formattedValue = "<div></div>";
      }
    }

    return formattedValue;
  }
  formatBadgeColor(field: any, row: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          //console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }

      if (typeof (value) !== "undefined" && value != "" && value != null) {
        className = "p-badge p-component p-badge-no-gutter p-badge-xs  p-mr-2 " + subClassName;
        var formattedValue = "<div class='" + className + "' title='" + value + "'></div>" + value;
      } else {
        var formattedValue = "<div></div>";
      }

    }

    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatIcon(value: any, args: any) {
    let options = args[1].split(",");
    let more_class = "";
    options.forEach((item) => {
      more_class += item + " ";
    });
    var formattedValue = "<i class='" + value + ' ' + more_class + "'></i>";
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { useGrouping: true }).format(value)
    return formattedValue;
  }


}
