import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { MailchimpService } from '../../configuration/integration/mailchimp.service';
import { CoreDialogService } from '../../core/dialogs/core-dialog.service';
import { MailMarketingSendComponent } from '../mail-marketing-send/mail-marketing-send.component';
import { MailMarketingTestComponent } from '../mail-marketing-test/mail-marketing-test.component';
import { MailjetService } from 'src/app/configuration/integration/mailjet.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-mail-marketing-list',
  templateUrl: './mail-marketing-list.component.html',
  styleUrls: ['./mail-marketing-list.component.scss']
})
export class MailMarketingListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  @ViewChild("grid") public grid: Table;
  public notificationsEmails: any[];
  public totalRecords: number;
  public loading: boolean = false;
  private lastGridEvent: any;
  public filters: any = {};
  public status = [];
  public fieldQuicks: any[] = [];
  public componentFilter: any;
  public hasFilterValue: boolean = false;
  public has_filter_panel: boolean = true;
  public show_filter_panel: boolean = false;
  public show_filter_panel_mobile: boolean = false;


  constructor(
    private router: Router,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private mailchimpService: MailchimpService,
    private mailjetService: MailjetService,
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService
  ) {
    this.createQuickFilters();
  }

  ngOnInit(): void {
    this.status = [
      { label: this.translateService.instant("component.mailMarketing.list.sent_status"), value: "sent" },
      { label: this.translateService.instant("component.mailMarketing.list.error_status"), value: "error" },
      { label: this.translateService.instant("component.mailMarketing.list.pending_status"), value: "pending" },
      { label: this.translateService.instant("component.mailMarketing.list.programmed_status"), value: "programmed" }
    ];
  }

  search() {
    this.lastGridEvent.first = 0;
    this.loadData(this.lastGridEvent);
  }

  clearFilters() {
    this.filters = {};
    this.search();
  }

  loadData($event) {
    this.lastGridEvent = $event;
    Promise.resolve(null).then(() => this.loading = true);
    var params = {
      _page: $event.first / $event.rows,
      _pageSize: $event.rows,
      _sortBy: $event.sortField ? $event.sortField : "",
      _sortDirection: $event.sortOrder > 0 ? "asc" : "desc",
      not_type: "email"
    };
    params = Object.assign(params, this.filters);

    this.notificationsService.all(params).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalRows;
        this.notificationsEmails = data.rows;
        Promise.resolve(null).then(() => this.loading = false);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        Promise.resolve(null).then(() => this.loading = false);
      }
    })
  }

  sendTest(id: number) {
    const ref = this.dialogService.open(MailMarketingTestComponent, {
      data: {
        notificationId: id
      },
      header: this.translateService.instant('component.mailMarketing.toStats.title'),
      width: "30%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.search();
    });
  }

  send(id: number) {

    const ref = this.dialogService.open(MailMarketingSendComponent, {
      data: {
        notificationId: id
      },
      header: this.translateService.instant('component.mailMarketing.send.title'),
      width: "25%",
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.search();
    });

  }
  duplicateAndSend(id: number) {
    //Duplicamos la líneas de notification_to y notification_email.
    this.notificationsService.duplicateMail(id).subscribe({
      next: (data: any) => {
        const ref = this.dialogService.open(MailMarketingSendComponent, {
          data: {
            notificationId: data
          },
          header: this.translateService.instant('component.mailMarketing.send.title'),
          width: "25%",
        });
        ref.onClose.subscribe((data: any) => {
          if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
          this.search();
        });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }

  cancelMail(id: number) {
    this.notificationsService.cancelMail(id).subscribe({
      next: (data: any) => {
        this.search();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    })
  }

  getType(type) {
    if (type == 'mailchimp') {
      //return "<div class='badge badge-block badge-success'>MAILCHIMP</div>";
      return "<img src='/assets/img/integrations/mailchimp.png' height='16' />";
    } else if (type == "mailjet") {
      //return "<div class='badge badge-block badge-success'>MAILJET</div>";
      return "<img src='/assets/img/integrations/mailjet.png' height='16' />";
    } else if (type == "mail_marketing") {
      //return "<div class='badge badge-block badge-success'>ESTANDAR</div>";
      //return "<img src='/assets/img/integrations/apirest.png' height='16' />";
      return "<div>SMTP</div>";
    }
  }

  getstatus(status) {
    if (status == 'sent') {
      var sent_status_string: string = this.translateService.instant("component.mailMarketing.list.sent_status");
      return "<div class='badge badge-block badge-success'>" + sent_status_string + "</div>";
    } else if (status == "pending") {
      var pending_status_string: string = this.translateService.instant("component.mailMarketing.list.pending_status");
      return "<div class='badge badge-block badge-warning'>" + pending_status_string + "</div>";
    } else if (status == "waiting") {
      var waiting_status_string: string = this.translateService.instant("component.mailMarketing.list.waiting_status");
      return "<div class='badge badge-block badge-warning'>" + waiting_status_string + "</div>";
    } else if (status == "programmed") {
      var programmed_status_string: string = this.translateService.instant("component.mailMarketing.list.programmed_status");
      return "<div class='badge badge-block badge-info'>" + programmed_status_string + "</div>";
    } else if (status == "error") {
      var error_status_string: string = this.translateService.instant("component.mailMarketing.list.error_status");
      return "<div class='badge badge-block badge-danger'>" + error_status_string + "</div>";
    } else {
      var default_status_string: string = this.translateService.instant("component.mailMarketing.list.default_status");
      return "<div class='badge badge-block badge-secondary'>" + default_status_string + "</div>";
    }
  }

  viewMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.id,
        //entity_id: data.notificationEmails.entity.id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
  };

  mailchimpDashboard(data) {
    this.mailchimpService.dashboard(data.id).subscribe({
      next: (data: any) => {
        if (data.success) {
          window.open(data.url);
        } else {
          this.messageService.add({ closable: false, severity: "error", detail: data.message });
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    })
  };

  mailjetDashboard(data) {
    this.mailjetService.dashboard(data.id).subscribe({
      next: (data: any) => {
        if (data.success) {
          window.open(data.url);
        } else {
          this.messageService.add({ closable: false, severity: "error", detail: data.message });
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    })
  };

  delete(id) {
    this.coreDialogService.confirm({
      message: this.translateService.instant('component.mailMarketing.list.delete_message'),
      accept: () => {
        this.notificationsService.delete(id).subscribe({
          next: (res: any) => {
            this.messageService.add({ severity: 'success', summary: this.translateService.instant('general.confirmation_delete') });
            this.search();
          },
          error: (error: any) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.title });
          }
        });
      },
      reject: () => {
      }
    });
  }


  createQuickFilters() {

    //Los buscamos en la sesión
    var sessionInfo = this.sessionStorage.retrieve("mailMarketing.mailMarketing");
    var selectedFields = [];

    if (sessionInfo != null) {
      this.hasFilterValue = true;
      this.filters.status = (sessionInfo.filters.find(m => m.status) != undefined ? sessionInfo.filters.find(m => m.status).status : null);
      this.filters.subject = (sessionInfo.filters.find(m => m.subject) != undefined ? sessionInfo.filters.find(m => m.subject).subject : null);
    }
  }

  toggleFilter() {
    this.show_filter_panel = !this.show_filter_panel;
  }
  toggleFilterMobile() {
    this.show_filter_panel_mobile = !this.show_filter_panel_mobile;
  }
  loadFilterAndSearch() {
    this.sessionStorage.clear("mailMarketing.mailMarketing");
    if (this.filters.status != null || (this.filters.subject != null && this.filters.subject != "")) {
      this.hasFilterValue = true;
      var sessionInfo = { filters: [] };

      if (this.filters.status != null) {
        sessionInfo.filters.push({ status: this.filters.status });
      }
      if (this.filters.subject != null && this.filters.subject != "") sessionInfo.filters.push({ subject: this.filters.subject });
      this.sessionStorage.store("mailMarketing.mailMarketing", sessionInfo);
    } else {
      this.hasFilterValue = false;
    }
    this.search();
  }

}
