import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { EntitiesFieldsService } from '../../entities-fields.service';
import { RolesService } from '../../roles/roles.service';
import { EntityFieldMasiveOptionsComponent } from './entity-field-masive-options/entity-field-masive-options.component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { AddItemToEntityFieldListComponent } from './add-item-to-entity-field-list/add-item-to-entity-field-list.component';
import { TranslateService } from '@ngx-translate/core';
import { ComponentService } from '../../../core/view/component.service';
import { EntitiesService } from '../../entities.service';
@Component({
  selector: 'app-entities-detail-fields',
  templateUrl: './entities-detail-fields.component.html',
  styleUrls: ['./entities-detail-fields.component.scss']
})
export class EntitiesDetailFieldsComponent implements OnInit {
  public control_types = [
    { name: 'input-text' },
    { name: 'input-number' },
    { name: 'select' },
    { name: 'label' },
    { name: 'dropdown' },
    { name: 'dropdown-multiple' }
  ];
  public data_types = [
    { name: this.translateService.instant("component.entity.detail.string"), value: 'string' },
    { name: this.translateService.instant("component.entity.detail.textarea"), value: 'textarea' },
    { name: this.translateService.instant("component.entity.detail.number"), value: 'number' },
    { name: this.translateService.instant("component.entity.detail.date"), value: 'date' },
    { name: this.translateService.instant("component.entity.detail.boolean"), value: 'boolean' },
    { name: this.translateService.instant("component.entity.detail.list"), value: 'list' },
    { name: this.translateService.instant("component.entity.detail.list_multiple"), value: 'list_multiple' }
  ];
  public entityField: any = {
    entity_id: this.config.data.entity_id,
    data_type: this.data_types[0].value,
    is_base_field: 0,
    configuration: {},
    control_type: "input-text",
    model_property: "custom_",
    is_disabled: false,
  };

  public selected_data_type: any;

  public code: any;
  public text: any;
  public items: any[] = [];
  public modelMonaco: any = {};
  public editorOptions = { theme: 'vs-dark', language: 'json' };
  public roles: any[] = [];
  public selectedRoles: any[] = [];
  public require_roles = false;
 
  constructor(
    private entitiesFieldsService: EntitiesFieldsService,
    private messageService: MessageService,
    private rolesService: RolesService,
    private config: DynamicDialogConfig,
    private router: Router,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private entitiesService: EntitiesService,
    public componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.loadData(this.config.data.id);
    this.loadRoles();
  }
  loadData(id) {
    if (id != 0 && id != null) {
      this.entitiesFieldsService.get(id).subscribe({
        next: (data: any) => {
          this.entityField = data;
          this.entityField.entity_code = (this.entityField.entity != null ? this.entityField.entity.code : this.entityField.entity_custom.code);

          this.entityField.description = this.componentService.getFieldDescription(this.entityField, false);

          //TODO: this.selected_data_type = this.attachment.selected_data_type;
          //if (this.entityField.configuration == null) this.entityField.configuration = { options: { items: [] }, field_text: 'text', field_value: 'code' };
          this.selectedRoles = (this.entityField.configuration != null ? this.entityField.configuration['viewRoles'] : null);
          if (this.selectedRoles?.length > 0) this.require_roles = true;
          this.modelMonaco = (this.entityField.configuration != null ? JSON.stringify(this.entityField.configuration, null, "\t") : JSON.stringify({}, null, "\t"));
          this.updateNameType();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    } else {
      this.modelMonaco = "{}";
    }
  }

  loadRoles() {
    this.rolesService.all({}).subscribe({
      next: (data: any) => {
        this.roles = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  editorInit(editor: MonacoStandaloneCodeEditor) { }

  onMonacoChange(event) {
    this.entityField.configuration = JSON.parse(this.modelMonaco);
  }

  save(closeWindow = true) {
    if (this.entityField.id === "undefined") this.updateControlType(); //Solo loo hacemos si es nuevo, sino se deja como estaba.
    if (this.entityField.data_type == "boolean" && this.entityField.default_value == false) this.entityField.default_value = null;
    if (this.entityField.data_type == "textarea") this.entityField.data_type = "string";
    if (!this.isEmpty(this.modelMonaco)) {
      //this.entityField.configuration = JSON.parse(this.modelMonaco);
    }

    //por conpatibilidad con lo anterior ELIMINADO: 12/04/24 para poder utilizar traducciones. Name tiene que ser como model_property.
    this.entityField.name = this.entityField.description;

    if (this.require_roles) {
      this.entityField.configuration['viewRoles'] = this.selectedRoles;
    } else {
      this.entityField.configuration['viewRoles'] = [];
    }
    this.entityField.configuration["allowedUpdateMasive"] = true;

    //si es un dropdown customizado, configuramos campos del dropdown
    if ((this.entityField.control_type == "dropdown" || this.entityField.control_type == 'dropdown-multiple') && this.entityField.is_base_field == false) {
      this.entityField.configuration["field_text"] = 'text';
      this.entityField.configuration["field_value"] = 'code';
    }

    if (typeof this.entityField.id === "undefined") {

      this.entitiesFieldsService.add(this.entityField).subscribe({
        next: (data: any) => {
          if (closeWindow) {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.entity.detail_fields.add_correct") });
            this.dynamicDialogRef.close(data);
            //Para cargarlo  en la cache
            this.entitiesService.initCache((success) => { });
          } else {
            this.entityField = data;
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields.elements_add_correct") });
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });

    } else {
      this.entitiesFieldsService.save(this.entityField.id, this.entityField).subscribe({
        next: (data: any) => {
          if (closeWindow) {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.entity.detail_fields.save_correct") });
            this.dynamicDialogRef.close(data);
            //Para cargarlo  en la cache
            this.entitiesService.initCache((success) => { });
          } else {
            this.entityField = data;
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields.elements_add_correct") });
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.entity.delete_message", { name: this.entityField.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.entitiesFieldsService.delete(this.entityField.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
            //Para cargarlo  en la cache
            this.entitiesService.initCache((success) => { });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    })
  }
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  addItem(element) {
    const ref = this.dialogService.open(AddItemToEntityFieldListComponent, {
      data: {
        element: element,
        //entityField_id: this.entityField.id,
        entityField: this.entityField
      },
      showHeader: false,
      //header: element ? "Elemento de la lista",
      width: '70%',
      //baseZIndex: 1000
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        this.entityField.configuration = data.configuration;
        this.modelMonaco = JSON.stringify(this.entityField.configuration, null, "\t");
      }
    });
  }

  deleteItem(item) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.entity.detail_fields.delete_message", { name: item.code }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        var i = 0;
        this.entityField.configuration.options.items.forEach(element => {
          if (element.code == item.code) {
            this.entityField.configuration.options.items.splice(i, 1);
          }
          i++;
        });
        this.modelMonaco = JSON.stringify(this.entityField.configuration, null, "\t");

        if (this.entityField.id) {
          this.entitiesFieldsService.save(this.entityField.id, this.entityField).subscribe({
            next: (data: any) => {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.entity.detail_fields_fields.delete_message") });
            },
            error: (error:any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
          });
        }
      },
      reject: () => {

      }
    });
  }

  updateControlType() {
    if(this.entityField.configuration.formatFn != null) delete this.entityField.configuration.formatFn;
    switch (this.selected_data_type) {
      case 'string':
        this.entityField.data_type = 'string';
        this.entityField.control_type = 'input-text'; //O input-textarea. Hay que contemplar esto
        break;
      case 'textarea':
        this.entityField.data_type = 'string';
        this.entityField.control_type = 'input-textarea';
        break;
      case 'number':
        this.entityField.data_type = 'number';
        this.entityField.control_type = 'input-number';
        break;
      case 'date':
        this.entityField.data_type = 'date';
        this.entityField.control_type = 'input-datetime';
        this.entityField.configuration.formatFn = "date|dd/MM/YYYY HH:mm";
        break;
      case 'boolean':
        this.entityField.data_type = 'boolean';
        this.entityField.control_type = 'checkbox';
        break;
      case 'list':
        this.entityField.data_type = 'list';
        this.entityField.control_type = 'dropdown';
        break;
      case 'list_multiple':
        this.entityField.data_type = 'list_multiple';
        this.entityField.control_type = 'dropdown-multiple';
        break;
      default:
        break;
    }
  }

  updateNameType() {
    switch (this.entityField.control_type) {
      case 'input-text':
        this.selected_data_type = 'string';
        break;
      case 'input-number':
        this.selected_data_type = 'number';
        break;
      case 'input-textarea':
        this.selected_data_type = 'textarea';
        break;
      case 'input-datetime':
        this.selected_data_type = 'date';
        break;
      case 'checkbox':
        this.selected_data_type = 'boolean';
        break;
      case 'dropdown':
        this.selected_data_type = 'list';
        break;
      case 'dropdown-multiple':
        this.selected_data_type = 'list_multiple';
        break;
    }
    /*if (this.selected_data_type == 'list') {
      if (this.entityField.data_type == "list_multiple") this.selected_data_type = 'list_multiple';
    }*/
  }

  addItemMasive() {
    const ref = this.dialogService.open(EntityFieldMasiveOptionsComponent, {
      data: {},
      header: this.translateService.instant("component.entity.detail_fields.add_masive"),
      width: '70%',
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe(data => {
      if (data) {
        if (this.isEmpty(this.entityField.configuration)) this.entityField.configuration = { options: { items: [] }, field_text: 'text', field_value: 'code' };
        if (this.entityField.configuration.options == null) this.entityField.configuration['options'] = {};
        this.entityField.configuration.options['items'] = [];

        data.forEach(keyValue => {
          //Si encuentra su índice en las opciones existentes, no lo añade, sino que lo sobreescribe:
          var index = this.entityField.configuration.options?.items?.findIndex(op => op.code == keyValue[0]);
          if (index >= 0) {
            this.entityField.configuration.options.items[index] = { code: keyValue[0], text: keyValue[1] };
          } else {
            //Si no lo encuentra, simplemente lo añade:
            this.entityField.configuration.options?.items?.push({ code: keyValue[0], text: keyValue[1] });
          }
        });
        this.save(false);
        this.modelMonaco = JSON.stringify(this.entityField.configuration, null, "\t");
      }
    });
  }

  exportExcel() {
    var aux = [];
    //For que recorre cada row y column y devuelve un objeto que será exportado a excel:
    var obj = {};
    this.entityField.configuration?.options?.items.forEach(row => {
      obj[row.code] = row.text;
    });
    aux.push(obj);
    //Promise.resolve(null).then(() => this.loading = false);
    const worksheet = XLSX.utils.json_to_sheet(this.entityField.configuration?.options?.items);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
