import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Panel } from 'primeng/panel';
import { ComponentService } from '../../../component.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  @ViewChildren('panel') panels: QueryList<Panel>;

  public searchText: any = "";
  public fields: any = {};
  public entities: any[] = [];
  public originalFieldsFromData: any = {};
  public selectedEntitiesFieldsFilter: any = {};
  public selectedEntitiesFields = [];

  constructor(
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.fields = this.config.data.fields.filter((m: any) => m.configuration.excludeFromFilters != true);

    this.fields.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()));
    
    this.originalFieldsFromData = JSON.stringify(this.fields);
    
    this.fields.forEach(field => {
      if (this.entities.find(m => m.code == field.entity_code) == null) {
        this.entities.push({ code: field.entity_code, name: field.entity_name });
      }
    });   

    this.selectedEntitiesFieldsFilter = this.config.data.selectedEntitiesFieldsFilter;
    this.fields.forEach((item: any) => {
      var selected = this.selectedEntitiesFieldsFilter.find(m => m.id == item.id);
      if (selected != null) {
        item.selected = true;
        this.selectedEntitiesFields.push(item.id+"");
      } else {
        item.selected = false;
      }
    });
    
  };

  fieldsFiltered(search_text:string) {
    if(search_text==null) search_text = "";
    return this.fields.filter(m => m.description.toLowerCase().indexOf(search_text.toLowerCase())>=0);
  }

  fieldsInEntitiesField(name: any, search_text:string) {
    if(search_text==null) search_text = "";
    if(search_text!=""){
      //abrimos los paneles que estuviesen cerrado al buscar
      for(let i=0; i<this.panels.length; i++){
        let panel = this.panels.toArray()[i];
        if(panel.collapsed){
          let headerIcon:any = document.querySelector("#panel-"+i+" .p-panel-header-icon");
          headerIcon.click();
          panel.collapsed = false;
        }
      }
    }
    return this.fields.filter(m => m.entity_name == name && m.description.toLowerCase().indexOf(search_text.toLowerCase())>=0);
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
  save() {
    this.dynamicDialogRef.close(this.selectedEntitiesFields);
  }
  clear() {
    this.searchText = "";
    this.fields = JSON.parse(this.originalFieldsFromData);
  }
  /*search() {
    if (this.searchText != "") {
      this.fields = this.fields.filter(m => m.description.toLowerCase().includes(this.searchText.toLowerCase()));
    } else {
      this.fields = JSON.parse(this.originalFields);
    }
    
  }*/
  isInFieldGroup() {

  }
}
