import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { CampaignsContactsService } from '../campaigns-contacts.service';
import { CampaignsOrganizationsService } from '../campaigns-organizations.service';
import { CampaignsService } from '../campaigns.service';

@Component({
  selector: 'app-add-campaigns-to-entity',
  templateUrl: './add-campaigns-to-entity.component.html',
  styleUrls: ['./add-campaigns-to-entity.component.scss']
})
export class AddCampaignsToEntityComponent {
  public campaigns: any[] = [];
  public entity;
  public results: any[] = [];
  public selected: any[] = [];
  public selected_campaign_id;
  public filters: any = {};
  public is_valid: boolean = false;
  public id;
  public add_organizations_checkbox = false;
  public add_contacts_checkbox = false;

  constructor(
    private campaignsService: CampaignsService,
    private campaignsOrganizationsService: CampaignsOrganizationsService,
    private campaignsContactsService: CampaignsContactsService,
    private tasksService: TasksService,
    private meetingsService: MeetingsService,
    private transactionsService: TransactionsService,
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit() {
    this.entity = this.config.data.entity;
    this.id = this.config.data.id;

    if (this.entity == "organization") {
      this.filters.skip_by_organization_id = this.id;
    }
    if (this.entity == "contact") {
      this.filters.skip_by_contact_id = this.id;
    }

    this.selected_campaign_id = this.config.data.campaign_id;
    if (this.selected_campaign_id != null) this.filters.include_campaign_id = this.selected_campaign_id;
    this.filters.skip_passed_campaigns = true;

    this.loadCampaigns();
  }

  loadCampaigns() {
    this.campaignsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  validateFilters(): boolean {
    for (const key in this.filters) {
      if (this.filters[key].length >= 3) {
        this.is_valid = true;
        return true;
      }
    }
    this.is_valid = false;
    return false;
  }
  onInputChange(): void {
    this.is_valid = this.validateFilters();
  }

  saveCampaigns() {
    switch (this.entity) {
      case "contact":
        this.saveContactCampaigns();
        break;
      case "organization":
        this.saveOrganizationCampaigns();
        break;
      case "task":
        this.saveTaskCampaigns();
        break;
      case "meeting":
        this.saveMeetingCampaigns();
        break;
      case "mailMarketing":
        this.saveMailMarketingCampaigns();
        break;
      case "oportunity":
      case "sale":
      case "transaction":
        this.saveTransactionCampaigns();
        break;
      default:
        break;
    }
  }
  saveOrganizationCampaigns() {
    this.campaignsOrganizationsService.addMultiplesCampaignsToOrganization({ organization_id: this.id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveContactCampaigns() {
    this.campaignsContactsService.addMultiplesCampaignsToContact({ contact_id: this.id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  saveTaskCampaigns() {
    ///TODO: traducir component.reports.added_elements_to_task

    if (this.selected_campaign_id) {
      this.tasksService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: [this.id], add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  saveMeetingCampaigns() {
    ///TODO: traducir component.reports.added_elements_to_meeting

    if (this.selected_campaign_id) {
      this.meetingsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: [this.id], add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  saveMailMarketingCampaigns() {
    ///TODO: traducir component.reports.added_elements_to_mailMarketing

    if (this.selected_campaign_id) {
      this.notificationsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: [this.id], add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }


  saveTransactionCampaigns() {
    ///TODO: traducir component.reports.added_elements_to_oportunity/sale/transaction

    if (this.selected_campaign_id) {
      this.transactionsService.addMultiplesToCampaign({ campaign_id: this.selected_campaign_id, ids: [this.id], add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
          this.dynamicDialogRef.close();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
}