<!--<div>

</div>
<app-view #view [code]="'tasks.quick'" [model]="task"></app-view>
-->

<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title p-d-flex">
      <div class="flex-grow-1">{{'task.entity?.name' | translate}}</div>
    </div>
  </ng-template>
  <div class="p-grid">

    <div class="p-col-12 p-m-0 p-field">
      <label for="task_action">{{'task.entity_fields.task_action_id' | translate}}</label>
      <p-dropdown name="task_action" [options]="task_actions" [(ngModel)]="task.task_action_id" optionLabel="name"
        optionValue="id" appendTo="body"></p-dropdown>
    </div>

    <div class="p-col-12 p-m-0 p-field">
      <label for="subject">{{'task.entity_fields.subject' | translate}}</label>
      <input [(ngModel)]="task.subject" id="subject" name="subject" type="text" autocomplete="false" />
    </div>
    <div class="p-col-12 p-m-0 p-field">
      <label for="task_action_scheduled_date">{{'task.entity_fields.task_action_scheduled_date' | translate}}</label>
      <app-view-component-datetime [(ngModel)]="task.task_action_scheduled_date" [ngModelOptions]="{standalone: true}"
        [show-time]="true">
      </app-view-component-datetime>
    </div>
    <div class="p-col-12 p-m-0 p-field">
      <label for="responsible_user_id">{{'task.entity_fields.responsible_user_id' | translate}}</label>
      <p-dropdown name="responsible_user_id" [options]="responsibles" [(ngModel)]="task.responsible_user_id"
        optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
    </div>
    <div class="p-col-12 p-m-0 p-field">
      <label for="url_ok">{{'task.entity_fields.task_status_id'|translate}}</label>
      <p-dropdown name="task_status" [options]="task_status" [(ngModel)]="task.task_status_id" optionLabel="name"
        optionValue="id" appendTo="body"></p-dropdown>
    </div>
  </div>
</p-card>

<br />

<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title p-d-flex">
      <div class="flex-grow-1">{{'organization.entity?.name'|translate}}</div>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 p-m-0 p-field">
      <label for="url_ok">{{'component.leads.if_organization_not_found' | translate}}</label>
      <p-dropdown name="type_process_organization" [options]="typeProcessOptions"
                  [(ngModel)]="task.type_process_organization" optionLabel="name" optionValue="code" appendTo="body"
                  [filter]="true" filterBy="name" [showClear]="true"></p-dropdown>
      <div *ngIf="task.type_process_organization" class="p-message p-message-info p-p-2"
           style="font-size: 13px; display: flex; align-items: center;">
        <i class="pi pi-info-circle p-mr-2"></i>
        {{getInfoTypeProcessOptions()}}
      </div>
    </div>
    <div class="p-col p-m-0 p-field"
         *ngIf="hasPermission && hasZonesRead && task.type_process_organization == 'create'">
      <label for="responsible">{{'task.entity_fields.responsible_user_id' | translate}}</label>
      <p-dropdown name="responsible" [options]="responsibles" [(ngModel)]="task.organization_responsible_id"
                  optionLabel="name" optionValue="id" appendTo="body" [filter]="true" filterBy="name"
                  [showClear]="true"></p-dropdown>
    </div>
    <div class="p-col p-m-0 p-field"
         *ngIf="hasPermission && hasZonesRead && task.type_process_organization == 'create'">
      <label for="zone">{{'task.entity_fields.zone_id'|translate}}</label>
      <p-dropdown name="zone" [options]="zones" [(ngModel)]="task.zone_id" optionLabel="name" optionValue="id"
                  appendTo="body" [filter]="true" filterBy="name" [showClear]="true"></p-dropdown>
    </div>

    <!--div class="p-col-12 p-m-0 p-field" *ngIf="task.type_process_organization == 'assign'">
    <label for="url_ok">{{'task.entity_fields.organization_id' | translate}}</label>
    <p-dropdown name="organization" [options]="organizations" [(ngModel)]="selectedOrganization" optionLabel="name"
      appendTo="body" [filter]="true" filterBy="name" [showClear]="true" (onChange)="changeOrg($event)"
      (onFilter)="loadOrganizations($event)"></p-dropdown>
  </div
  <div class="p-col-12 p-m-0 p-field" *ngIf="task.type_process_organization == 'assign'">
    <label for="url_ok">{{'task.entity_fields.organization_id' | translate}}</label>
    <p-dropdown [options]="organizations" [(ngModel)]="selectedOrganization" optionLabel="name" [showClear]="true"
      appendTo="body" [filter]="true" filterBy="name" (onChange)="changeOrg($event)">
      <ng-template pTemplate="filter" let-options="options">
        <div class="flex gap-1">
          <div class="p-inputgroup" (click)="$event.stopPropagation()" style="width: 100%;">
            <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
            <input type="text" pInputText placeholder="" [(ngModel)]="filterValue"
              (keyup)="loadOrganizations($event)" style="width: 100%;" />
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="selectedOrganization">
          <div>{{ selectedOrganization.name }} <small  *ngIf="selectedOrganization?.zone_id">&nbsp;({{selectedOrganization.zone?.name}})</small></div>

        </div>
      </ng-template>
      <ng-template let-organization pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ organization.name }}</div>
          <div *ngIf="organization.zone_id"><small>{{organization.zone?.name}}</small></div>

        </div>
      </ng-template>
    </p-dropdown>
  </div>-->

  <div class="p-col-12 p-mb-6 p-field" *ngIf="task.type_process_organization == 'assign'">
    <label for="url_ok">{{'task.entity_fields.organization_id' | translate}}</label>

    <p-autoComplete [showEmptyMessage]="true" emptyMessage="Sin datos" [(ngModel)]="searchText" placeholder="{{'general.toolbar.filter' | translate}}"
                    [suggestions]="searchResults" class="topAutoComplete" (completeMethod)="loadOrganizations($event)" field="name"
                    (onSelect)="changeOrg($event)" minLength="3" [dropdown]="true" [showClear]="true">
      <ng-template let-result pTemplate="item">
        <div class="result-item">
          <div style="font-size: 15px;" [highlight]="searchText" [inputText]="result.name"
               customClasses="bg-transparent font-semibold color-green-axial">{{result.name}} {{result.subtitle_label}} </div>
          <div style="color:#909090;font-size: 11px" *ngIf="result.fulladdress" [highlight]="searchText"
               [inputText]="result.fulladdress" customClasses="bg-transparent font-semibold color-green-axial">
            {{result.fulladdress}}
          </div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>



    <div class="p-col p-m-0 p-field" *ngIf="task.type_process_organization == 'assign' && task.organization_id > 0">
      <label for="responsible">{{'organization.entity_fields.responsible_id' | translate}}</label>
      <div name="responsible" *ngIf="selectedOrganization?.responsible != null">
        {{selectedOrganization.responsible?.name}}
      </div>
      <div *ngIf="selectedOrganization?.responsible == null">{{'component.leads.no_responsible'| translate}}</div>
    </div>
    <div class="p-col p-m-0 p-field"
         *ngIf="hasZonesRead && task.type_process_organization == 'assign' && task.organization_id > 0">
      <label for="zone">{{'organization.entity_fields.zone_id'|translate}}</label>
      <div name="zone" *ngIf="selectedOrganization?.zone != null">{{selectedOrganization?.zone?.name}}</div>
      <div *ngIf="selectedOrganization?.zone == null">{{'component.leads.no_zone' | translate}}</div>
    </div>
  </div>
</p-card>

<div class="p-col-12 p-d-flex" *ngIf="hasPermission">
  <button pButton style="float: right;" (click)="cancel()" icon="pi pi-times"
    label="{{'general.action.cancel' | translate}}"></button>
  <div class="flex-grow-1"></div>
  <button pButton class="p-ml-2" style="float: right;" (click)="saveTask()" icon="pi pi-check"
    label="Procesar y crear actividad"></button>


</div>
