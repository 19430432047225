import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OrganizationsService } from '../organizations.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { OrganizationsDetailGeneralComponent } from '../organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsTypesService } from '../organizations-types.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { SessionStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from '../../configuration/countries/countries.service';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss']
})
export class OrganizationsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public organizationsFilters: any = {};
  public organizations: any[];
  public countries: any[];
  public totalRecords: any;
  public loading: boolean;
  public organizationType: any;
  public organizationTypeId: any;

  constructor(
    private organizationsService: OrganizationsService,
    private organizationsTypesService: OrganizationsTypesService,
    private countriesService: CountriesService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private viewsService: ViewsService,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.organizationTypeId = this.activatedRoute.snapshot.params.organizationTypeId;
    if (this.organizationTypeId != null) {
      this.loadOrganizationType();
      this.organizationsFilters.organization_type_id = [ parseInt(this.organizationTypeId) ];

      //marcamos para que este campo no se tenga en cuenta desde la sesion
      this.organizationsFilters._ignore_session_fields = [ "organization_type_id" ];
      this.organizationsFilters._excluded_fields = [ "organization_type_id" ];
    }
  }

  loadOrganizationType() {
    if (this.organizationTypeId != null) {
      this.organizationsTypesService.get(this.organizationTypeId).subscribe({
        next: (data: any) => {
          this.organizationType = data;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      })
    }
  }

  preview(organization) {
    this.router.navigate(['/organizations/', this.organizationTypeId ? this.organizationTypeId : 0, organization.id]);
  }

  add() {
    const ref = this.dialogService.open(OrganizationsDetailGeneralComponent, {
      data: {
        id: 0,
        organization_type_id: this.organizationTypeId
      },
      // header: "Nueva organización",
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) {
        this.preview(data);
      }
    })

  }

  delete(organization) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.organizations.delete_message", { name: organization.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.organizationsService.delete(organization.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            if (this.organizationTypeId) {
              this.router.navigate(['/organizations/' + this.organizationTypeId]);
            } else {
              this.router.navigate(['/organizations']);
            }
            this.view.fireEvent("organizations.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }

  edit(organization) {
    const ref = this.dialogService.open(OrganizationsDetailGeneralComponent, {
      data: {
        id: organization.id,
        organization_type_id: this.organizationTypeId
      },
      // header: "Nueva organización",
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data) { }
    });
  }
  onEvent(event: any) {
    if (event.component == "organizations.list.filters") {
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("organizations.list.results", "reload");
      }
    } else if (event.component == "organizations.list.results") {


      if (event.event == "dblclick") this.preview(event.data);
      if (event.event == "action" && event.action.name == "add") this.add();
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("organizations.list.results", "reload", event.data);
      }
    }
  }
}
