import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PermissionsGroupService } from 'src/app/configuration/roles/permissions-group.service';
import { PermissionsService } from 'src/app/configuration/roles/permissions.service';
import { RolesPermissionsService } from 'src/app/configuration/roles/roles-permissions.service';
import { RolesService } from 'src/app/configuration/roles/roles.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CustomComponent } from 'src/app/core/view/component/custom/custom-component.interface';
import { FeaturesService } from 'src/app/features/features.service';
import { LoginService } from 'src/app/login/login.service';
import { UsersService } from '../../users.service';
import { UserRolesService } from './user-roles.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, CustomComponent {
  @Input() public data: any;
  @Input() public entity: any;
  public userRole: any = {};
  public rolePermissions: any = [];
  public selectedPermissions: any = [];
  public roles: any = [];
  public user: any = {
    userPermissions: []
  };
  public permissions: any = [];
  public permissionsGroups: any = [];
  public firstLoading = true;
  public features: any[];
  public checked_all: boolean;
  public allPermissions: string[];

  public roles_configuration: boolean;

  constructor(
    private loginService: LoginService,
    private userRolesService: UserRolesService,
    private rolesService: RolesService,
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private permissionsGroupsService: PermissionsGroupService,
    private rolePermissionsService: RolesPermissionsService,
    private featuresService: FeaturesService,

    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private config: DynamicDialogConfig,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.roles_configuration = this.loginService.hasPermission("ROLES_CONFIGURATION");

    /*
    this.userRole.user_id = this.config.data.user.id;
    this.selectedRoles = this.config.data.selectedRoles;*/
    this.loadRoles();
    this.loadDataFeatures();

  }
  loadUser(id) {
    if (id) {
      this.usersService.get(id).subscribe(
        data => {
          this.user = data;
          this.selectedPermissions = this.user.userPermissions.map(m => m.permission_id + "");
          this.loadRolePermissions();
          //this.rolePermissions = this.user.role.rolePermissions.map(m => m.permission_id + "");
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.firstLoading = false;
    }
  }

  loadRoles() {
    this.rolesService.all({}).subscribe({
      next: (data: any) => {
        this.roles = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadPermissionsGroups() {
    this.permissionsGroupsService.all({}).subscribe({
      next:(data:any) => {
        this.permissionsGroups = data.rows;
        this.loadPermissions();
      },
      error: (error:any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  loadPermissions() {
    var ids = this.features.map(m => m.id);
    this.permissionsService.all({ featureIds: ids }).subscribe({
      next: (data: any) => {
        this.permissions = data.rows;
        this.allPermissions = this.permissions.map(permission => permission.id + "");
        //Carga usuario:
        this.loadUser(this.config.data.id);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadRolePermissions() {
    this.selectedPermissions = this.user.userPermissions.map(m => m.permission_id + "");
    if (this.user.role_id) {
      this.data.role_id = this.user.role_id;
      this.rolePermissionsService.all({ role_id: this.user.role_id }).subscribe({
        next: (data: any) => {
          this.rolePermissions = data.rows.map(m => m.permission_id + "");

          if (!this.firstLoading) {
            this.selectedPermissions = this.rolePermissions;
          } else {
            //Concatena los permisos de este rol + los permisos asignados manualmente al usuario.
            this.selectedPermissions = this.selectedPermissions.concat(this.rolePermissions).filter((v, i, a) => a.indexOf(v) === i);
            this.firstLoading = false;
          }
          this.updateChecked();
        }, error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.firstLoading = false;
    }
  }

  getPermissions(group) {
    if (this.permissions == null) return [];
    return this.permissions.filter(m => m.permission_group_id == group.id);
  }

  addPermissions() {
    //Añadir permisos al usuario:
    let finalUserPermissions = this.selectedPermissions.map(permission_id => {
      let existentPermissions = this.user.userPermissions.filter(m => m.permission_id == permission_id && !m.deleted);
      if (existentPermissions.length > 0) {
        return existentPermissions[0];
      } else {
        return { user_id: this.user.id, permission_id: parseInt(permission_id) };
      }
    });
    this.user.userPermissions = finalUserPermissions;
  }

  save() {
    if (this.user != null && this.user.role_id > 0) {
      if (!this.user.id) {
        this.usersService.add(this.user).subscribe({
          next: (data: any) => {
            this.user.id = data.id;
            this.dynamicDialogRef.close(this.user);
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.users.detail.add_user_correct') });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      } else {
        delete this.user.role;
        this.usersService.save(this.user.id, this.user).subscribe({
          next: (data: any) => {
            this.dynamicDialogRef.close(data);
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.users.detail.save_user_correct') });
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        })
      }
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('') })
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.users.delete_message_role", { name: this.userRole.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.userRolesService.delete(this.userRole.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant('general.confirmation_delete') });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }

  loadDataFeatures() {
    this.featuresService.all({}).subscribe({
      next: (data: any) => {
        this.features = data.rows;
        this.loadPermissionsGroups();
      }, error: (error: any) => {
        this.messageService.add({ severity: 'error', detail: error.error.title });
      }
    })
  }

  check_all() {
    this.allPermissions.sort();
    this.selectedPermissions.sort();

    //Si el check está marcado, añadirá todos los permisos posibles al array.
    if (this.checked_all) {
      this.selectedPermissions = this.allPermissions;
    } else {
      //Y si no, los borra.
      this.selectedPermissions = [];
      this.loadRolePermissions();
    }
  }
  updateChecked() {
    if (this.selectedPermissions.length == this.allPermissions.length) {
      this.checked_all = true;
    } else {
      this.checked_all = false;
    }
  }

  onEvent(component: string, event: string, args: any) {
    if (component == "userRole.custom" && event == "reload") {
      args.role_id = this.user.role_id;
      args.userPermissions = this.user.userPermissions;
      this.user = args;
      this.addPermissions();
      this.save();
    }
  }
}
