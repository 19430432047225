import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ReportsService } from '../../reports.service';
import { NgForm } from '@angular/forms';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ComponentService } from '../../../core/view/component.service';
import { LoginService } from 'src/app/login/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-masive-update',
  templateUrl: './report-masive-update.component.html',
  styleUrls: ['./report-masive-update.component.scss']
})
export class ReportMasiveUpdateComponent implements OnInit {

  @ViewChild("formDetails") public formDetails: NgForm;

  public yes_no = [
    { name: this.translateService.instant("component.reports.detail.yes"), value: 1 },
    { name: this.translateService.instant("component.reports.detail.no"), value: 0 },
  ];

  public columns = [];
  public selectedColumn: any = {};
  public entity: any = [];
  public temporalCombos: any = {};
  public rowsLength: number;
  public hasSageFeature: boolean = false;
  public messages: any[] = [];

  public entitiesToFilter: any = [];
  public filterValuesOptions: any[] = [];
  public selectedField: any = {};

  constructor(private route: ActivatedRoute,
    private loginService: LoginService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private entitiesService: EntitiesService,
    private componentService: ComponentService,
    private translateService: TranslateService
  ) {
    this.hasSageFeature = this.loginService.hasFeature(31);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.columns, this.config.data.entity, this.config.data.rowsLength);
    });
  }

  loadData(columns, entity, rowsLength) {
    this.entitiesService.getByCode(entity.code).subscribe({
      next: (data: any) => {
        entity.fields.forEach(field => {
          this.entity = data;
          field.entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
        });

        this.entitiesToFilter = this.getSelectableEntityFields();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
    this.columns = columns;
    this.rowsLength = rowsLength;
  }


  getSelectableEntityFields() {
    if (this.entity?.fields != null) {

      //obtener entidades
      let entities = [];
      this.entity.fields.forEach(field => {
        if (field.model_property == "zone_id") {
        }
        if (this.componentService.isVisibleFieldCustom({ entityField: field }, null)) {
          entities.push(field);
          if (entities.find(m => m.code == field.entity_code) == null) {
            //Lo cogemos del field porque lo traemos para las entidades que no son la principal.
            entities.push({ code: field.entity_code, name: field.entity_name, shortname: field.shortname });
          }
        }
      });

      let dataAux = [];

      dataAux = entities.filter(m => m.configuration?.allowedUpdateMasive);

      let data = []
      entities.forEach((entity) => {
        let fields = dataAux.filter(m => m.entity_code == entity.code);
        fields.forEach((f) => {
          data.push({
            id: f.id,
            entity_field_id: f.id,
            entity_field_model_property: f.full_model_property,
            entity_field_model_property_label: f.full_model_property_label,
            description: (entity.shortname ? entity.shortname : entity.name) + " / " + f.description,
            label: (entity.shortname ? entity.shortname : entity.name) + " / " + f.description//(typeof (f.label) === "undefined" ? "" : f.label)
          });
        })

      });
      return data;
    } else {
      return [];
    }
  }

  getComboOptions(field, text) {
    if (field.configuration.options?.type == "api") {
      var url = "/api/" + field.configuration.options.url;
      if (text == "undefined") text = null;
      this.temporalCombos[field.id] = [];
      this.reportsService.customVoidCombos(url, { text: text }).subscribe({
        next: (data: any) => {

          this.temporalCombos[field.id] = data.rows;
          if (field.configuration.options.url == "users/combo" || field.configuration.options.url == "users") {
            //Añadimor el usuario actual
            this.temporalCombos[field.id].push({ id: -1, name: this.translateService.instant("component.reports.detail.actual_user") });
          }
          if (field.configuration.options.url == "transactionsStatus") {
            let transaction_type_id = (field.entity_code == "oportunity" ? 1 : 2);
            this.temporalCombos[field.id] = this.temporalCombos[field.id].filter(function (e) { return e.transaction_type_id == transaction_type_id });
          }
          //

          if (field.data_type == "list" || field.data_type == 'list_multiple') {
            field.configuration.options.items.forEach(element => {
              let id = field.configuration.field_value;
              let text = field.configuration.field_text;
              if (typeof this.filterValuesOptions[element.entity_field_id] === "undefined") this.filterValuesOptions[element.entity_field_id] = [];
              this.filterValuesOptions[element.entity_field_id].push({ [id]: element.code, [text]: element.text });

            });
          } else if (field.data_type == "number") {
            //if (typeof this.filterValuesOptions[field.id] === "undefined") 
            this.filterValuesOptions[field.id] = [];
            this.temporalCombos[field.id].forEach(element => {

              element.text = element[field.configuration.field_text];
              element.code = element[field.configuration.field_value];
              this.filterValuesOptions[field.id].push(element);
            })
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      field.configuration.options.items.forEach(e => {
        e.name = e.text;
        e.id = e.code;
      })
      this.temporalCombos[field.id] = field.configuration.options.items
      if (typeof this.filterValuesOptions[field.id] === "undefined") this.filterValuesOptions[field.id] = [];
      this.temporalCombos[field.id].forEach(element => {
        this.filterValuesOptions[field.id].push(element);
      })
    }
  }

  changeColumn(event: any, fieldConfig: any) {

    let field = this.entity.fields.find(m => m.id == this.selectedColumn.entity_field_id);
    if (field?.full_model_property == "organizations.zone_id" && this.hasSageFeature) {
      this.messages = [{ severity: 'warn', summary: '', detail: this.translateService.instant("component.reports.detail.organizations_with_erp_not_updated") }];// "Las organizaciones que ya estén vinculadas con su erp no serán actualizadas." }];
    } else {
      this.messages = [];
    }
    this.selectedColumn.entity_field_model_property = field?.full_model_property;
    if (field != null && (field.control_type == "dropdown" || field.control_type == "dropdown-multiple")) {
      this.getComboOptions(field, null);
    }
    this.selectedField = field;
  }

  getFilterOptionsOrValueType(element, filter) {
    if (typeof (this.entity.fields) !== "undefined") {
      let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
      if (field.length > 0) {
        //if (filter) return this.filterOptions(field[0].data_type, field[0].control_type, field[0]); ya no se llama desde la vista.
        if (!filter) return this.valueTypes(field[0].data_type, field[0].control_type);
      }
    }
  }

  valueTypes(data_type: string, control_type: string) {
    let valueType = "";
    if (data_type == "string") {
      valueType = 'input-text';
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      valueType = (data_type == "number" ? 'input-number' : "input-datetime");
    }
    else if (data_type == "list") {
      valueType = "list";
    } else if (data_type == "list_multiple") {
      valueType = 'dropdown-multiple';
    } else if (data_type == "boolean") {
      valueType = "boolean"
    } else {
      valueType = 'input-text';
    }

    if (control_type == "dropdown") {
      valueType = 'input-dropdown';
    }
    return valueType;
  }

  getFilterComboOptionsText(element: any, field_text: boolean) {
    let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
    if (field.length > 0) {
      if (field_text) {
        return field[0].configuration.field_text;
      } else {
        return field[0].configuration.field_value;
      }
    }
  }

  updateColumns() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.reports.detail.modify_values", { rowsLength: this.rowsLength }),
      header: this.translateService.instant("component.reports.detail.confirmate_update"),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.dynamicDialogRef.close(this.selectedColumn);
      },
      reject: () => {
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  dateToIso($event: Date, i: number, j: number) {
    this.selectedColumn.value = new Date($event.setSeconds(0)).toISOString();//$event.toISOString();
  };
}
