import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { LoginService } from 'src/app/login/login.service';
import { UsersService } from 'src/app/users/users.service';
import { EntitiesService } from '../../entities.service';
import { RolesService } from '../../roles/roles.service';
import { ZonesService } from '../../zones/zones.service';
import { HtmlPreviewComponent } from '../html-preview/html-preview.component';
import { TemplatesTypesService } from '../templates-types.service';
import { TemplatesService } from '../templates.service';
import { timeout } from 'rxjs';
import { BeeService } from '../../../core/bee.service';
import { ViewsService } from '../../../core/view/views.service';
import { CoreFormService } from '../../../core/forms/core-forms.service';
import { NgForm } from '@angular/forms';
import { TinyMCE } from 'tinymce';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-templates-detail',
  templateUrl: './templates-detail.component.html',
  styleUrls: ['./templates-detail.component.scss']
})
export class TemplatesDetailComponent implements OnInit {

  @ViewChild("formDetails") public formDetails: NgForm;
  @ViewChild("view") public view: ViewComponent;

  tinyMceConfig: any;

  public showTinyMCE: boolean = false;

  public template: any = {};
  public users: any[] = [];
  public zones: any[] = [];
  public roles: any[] = [];
  public selected_zone: any;
  public selected_zones: any[] = [];
  public selected_user: any;
  public selected_users: any[] = [];
  public selected_role: any;
  public selected_roles: any[] = [];

  public templateTypes: any[];
  public entities: any[];

  public zonesPermission: any;

  constructor(
    private templatesService: TemplatesService,
    private zonesService: ZonesService,
    private usersService: UsersService,
    private rolesService: RolesService,
    private templateTypesService: TemplatesTypesService,
    private entitiesService: EntitiesService,
    private loginService: LoginService,
    private coreFormService: CoreFormService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private beeService: BeeService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) {

  }

  ngOnInit(): void {

    this.zonesPermission = this.loginService.hasPermission("ZONES_READ");
    this.loadTemplateTypes();
    //if (!id) {
    this.loadEntities();
    //}

    //this.loadData(id);



    //this.configureTinyMce("entities/variables?entity_id=3");
    // this.preConfigTinyMce();

  }

  load() {
    var id = this.config.data.id;
    if (id) {
      this.templatesService.get(id).subscribe({
        next: (data: any) => {
          this.template = data;

          if (!this.template.beeplugin) {
            //setTimeout(function () {
            this.configureTinyMce("entities/variables?entity_id=" + this.template.entity_id);
            //}, 5000)
          } else {
            this.configureBee("entities/variables?entity_id=" + this.template.entity_id);
          }

          this.loadMetadata();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      this.template.template_type_id = this.config.data.template_type_id;
      var default_entity = this.entities.find(entity => entity.code == "contact");
      var default_template_type = this.templateTypes.find(template => template.code == "MAIL");

      this.template.entity_id = default_entity.id;
      this.template.template_type_id = default_template_type.id;
      this.beeService.start({}, null, null);
    }
  }

  preConfigTinyMce() {

    const that = this;
    this.tinyMceConfig =
    {
      promotion: false,
      height: 500,
      menubar: true,
      browser_spellcheck: true,
      contextmenu: false,
      plugins: [
        /*'preview',*/
        /*'formatpainter'*/
      ],
      toolbar: [
        'previewButton | undo redo | bold italic underline | fontselect fontsizeselect fontsize blocks fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton |'
      ],
      fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 30px 36px 38px 40px",

      content_css: "../assets/stylesTinymceCustom.css", //"https://assets.codepen.io/413052/templates-noneditable-styling-content.css",
      noneditable_noneditable_class: "mceNonEditable",
      toolbar_mode: 'wrap',
      language: 'es',
      language_url: '/assets/tinymce/langs/es.js',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      file_picker_types: 'image',
      paste_data_images: false,
      /* and here's our custom image picker*/
      onRemove: function () {
      },
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            //tenemos la url en base64. creamos el attachment
            //that.onBasicUpload(file, event.target.result);
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templatesService.UploadImage(datafile).subscribe({
              next: (data: any) => {
                cb(data.location);
                //FALTA Pintar la imagen con la url en el visor.... work in progress
              },
              error: (error: any) => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
    };

  }
  onChangeName() {
    if (this.template.id == null) this.template.subject = this.template.name;
  }

  configureTinyMce(url) {
    this.preConfigTinyMce();
    let params = "";
    const that = this;
    this.viewsService.getComboOptions(url).subscribe(
      data => {
        let result = data;
        let showLinesTransaction = false;
        result.forEach(element => {
          if (element.entity_navigation == "transactionsLines") showLinesTransaction = true;
        });

        this.tinyMceConfig["setup"] = function (editor) {

          let toggleState = false;
          /* example, adding a toolbar menu button */
          editor.ui.registry.addButton('previewButton', {
            text: that.translateService.instant('component.templates.detail.previewed'),
            onAction: function () {
              that.previewHTML();
            }
          })
          editor.ui.registry.addMenuButton('variablesButton', {
            text: that.translateService.instant('component.templates.detail.variables'),
            fetch: function (callback) {
              //TODO: SELECCIONAR EL QUE CORRESPONDA DE templates_types_id no entiendo como saber cual es el disponible.
              let items = [];
              result.forEach(element => {
                items.push({
                  type: 'nestedmenuitem',
                  text: element.entity_name,
                  getSubmenuItems: function () {
                    let aux = [];
                    element.options.forEach(option => {
                      aux.push({
                        type: 'menuitem',
                        text: option.text,
                        //icon: 'unlock',
                        onAction: function () {
                          editor.insertContent(option.action);
                        }
                      })
                    });
                    return aux;
                  }
                });
              });
              callback(items);
            }
          });
          if (showLinesTransaction) {
            editor.ui.registry.addButton('variablesLinesButton', {
              text: that.translateService.instant('component.templates.detail.add_lines_block'),
              onAction: function () {
                //that.addForeach()
                editor.insertContent('<p class="bucle mceNonEditable">@foreach(var item in @Model.transactionsLines){</p><table style=\"border-collapse: collapse; width: 100%;\" border=\"1\">\n<tbody>\n<tr>\n<td></td></tr>\n</tbody>\n</table><p class="bucle mceNonEditable">}</p>', { format: 'html' });

              }
            })
          }
        };
        this.showTinyMCE = true;
      }
    );

  }

  loadTemplateTypes() {
    this.templateTypesService.all({}).subscribe(
      data => {
        this.templateTypes = data.rows;
        if (this.templateTypes.length > 0 && this.template.id == null) {
          this.template.template_type_id = this.templateTypes[0].id;
          this.template.templatetype = this.templateTypes[0];
        }
      }, error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }
  loadEntities() {
    this.entitiesService.all({}).subscribe({
      next: (data: any) => {
        this.entities = data.rows;
        if (this.entities.length > 0 && this.template.id == null) {
          this.template.entity_id = this.entities[1].id;
        }
        this.load();
      }, error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  configureBee(url) {
    this.viewsService.getComboOptions(url).subscribe(
      variables => {
        var mergeTags = [];
        var specialLinks = [];
        variables.forEach((item: any) => {
          item.options.forEach(option => {
            mergeTags.push({
              name: item.entity_name + " - " + option.text,
              value: option.actionBlocks
            });
          });
        });

        if (this.template.beeplugin) {
          if (this.template.json != null) {
            this.beeService.start(JSON.parse(this.template.json), mergeTags, []);
          } else {
            this.beeService.start({}, mergeTags, []);
          }

        }
      });
  }
  loadData(id) {
    if (id) {
      this.templatesService.get(id).subscribe(
        data => {
          this.template = data;

          if (!this.template.beeplugin) {
            //setTimeout(function () {
            this.configureTinyMce("entities/variables?entity_id=" + this.template.entity_id);
            //}, 5000)
          } else {
            this.configureBee("entities/variables?entity_id=" + this.template.entity_id);
          }

          this.loadMetadata();
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.template.template_type_id = this.config.data.template_type_id;
      this.beeService.start({}, null, null);
    }
  }

  saveDb(event: any) {
    this.buildMetadata();
    /*if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }*/

    if (!this.template.html) {
      this.template.html = "<p></p>";
    }
    if (typeof this.template.id === "undefined") {
      this.template.is_default = 0;
      this.templatesService.add(this.template).subscribe(
        data => {
          this.template = data;

          this.loadData(this.template.id);
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.templates.detail.add_correct') });
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    } else {
      this.templatesService.save(this.template.id, this.template).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.templates.detail.save_correct') });
          //this.cancel();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      })
    }
  }
  save(event: any) {

    if (this.coreFormService.validate(this.formDetails)) {
      if (this.template.id && this.template.beeplugin) {
        this.beeService.save().then((data: any) => {
          this.template.beeplugin = true;
          this.template.json = data.jsonFile;
          this.template.html = data.htmlFile;
          this.saveDb(event);
        });
      } else {
        this.saveDb(event);
      }
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
  }
  cancel() {
    //this.tinyMceConfig.remove();
    this.showTinyMCE = false;
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.template.detail.delete_message", { name: this.template.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.templatesService.delete(this.template.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.cancel();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }

  save_permissions(message) {
    this.buildMetadata();
    this.templatesService.savePermissions(this.template.id, this.template).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', detail: message });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  save_zone() {
    //Guarda zona en tabla y lo elimina del dropdown.
    if (this.selected_zone != null) {
      this.zones.splice(this.zones.lastIndexOf(this.selected_zone), 1);
      this.selected_zones.push(this.selected_zone);
      this.selected_zone = null;
      this.save_permissions(this.translateService.instant('component.templates.detail.zone_assign_correct'));
    }
  }
  unlinkZone(zone) {
    //Guarda la zona en dropdown y lo elimina de la tabla.
    var erased = this.selected_zones.splice(this.selected_zones.lastIndexOf(zone), 1);
    this.zones.push(erased[0]);
    this.selected_zone = { name: this.translateService.instant('component.template.detail.select_zone') };
    this.save_permissions(this.translateService.instant('component.template.detail.zone_rejected_correct'));
  }
  save_role() {
    if (this.selected_role != null) {
      this.roles.splice(this.roles.lastIndexOf(this.selected_role), 1);
      this.selected_roles.push(this.selected_role);
      this.selected_role = null;
      this.save_permissions(this.translateService.instant('component.template.detail.role_assign_correct'));
    }
  }
  unlinkRole(role) {
    var erased = this.selected_roles.splice(this.selected_roles.lastIndexOf(role), 1);
    this.roles.push(erased[0]);
    this.selected_role = { name: this.translateService.instant("component.template.detail.select_role") };
    this.save_permissions(this.translateService.instant('component.template.detail.role_rejected_correct'));
  }
  save_user() {
    if (this.selected_user != null) {
      this.users.splice(this.users.lastIndexOf(this.selected_user), 1);
      this.selected_users.push(this.selected_user);
      this.selected_user = null;
      this.save_permissions(this.translateService.instant('component.template.detail.user_assign_correct'));
    }
  }
  unlinkUser(user) {
    var erased = this.selected_users.splice(this.selected_users.lastIndexOf(user), 1);
    this.users.push(erased[0]);
    this.selected_user = { name: this.translateService.instant('component.template.detail.select_user') };
    this.save_permissions(this.translateService.instant('component.template.detail.user_rejected_correct'));
  }
  buildMetadata() {
    var finalZones = [];
    var finalUsers = [];
    var finalRoles = [];

    this.selected_zones.forEach(zone => {
      finalZones.push(zone.id);
    });
    this.selected_roles.forEach(role => {
      finalRoles.push(role.id);
    });
    this.selected_users.forEach(user => {
      finalUsers.push(user.id);
    });

    this.template.metadata = {
      permissions: {
        zones: finalZones,
        users: finalUsers,
        roles: finalRoles
      }
    }
  }

  loadMetadata() {
    var zonesIds = this.template.metadata["permissions"]["zones"];
    var usersIds = this.template.metadata["permissions"]["users"];
    var rolesIds = this.template.metadata["permissions"]["roles"];

    //Carga la tabla y dropdown de Usuarios:
    this.usersService.combo({}).subscribe(
      data => {
        var tempUsers = [];
        this.users = data.rows;
        this.users.forEach(user => {
          if (usersIds.indexOf(user.id) > -1) {
            this.selected_users.push(user);
          } else {
            tempUsers.push(user);
          }
          this.users = tempUsers;
        });
        /*this.selected_users = this.users.filter(function (e) {
          return usersIds.indexOf(e.id) > -1;
        });*/
      },
      error => {

      }
    );

    //Carga la tabla y dropdown de Zonas:
    if (this.zonesPermission) {

      this.zonesService.combo({}).subscribe({
        next: (data: any) => {
          var tempZones = [];
          this.zones = data.rows;
          this.zones.forEach(zone => {
            if (zonesIds.indexOf(zone.id) > -1) {
              this.selected_zones.push(zone);
            } else {
              tempZones.push(zone);
            }
            this.zones = tempZones;
          });

          /*this.selected_zones = this.zones.filter(function (e) {
            return zonesIds.indexOf(e.id) > -1;
          });*/
        },
        error: (error: any) => {

        }
      });
    }

    /*/Carga la tabla y dropdown de Roles:
    this.rolesService.getComboRoles({rolesIds: rolesIds}).subscribe(
      data=>{
        this.selected_roles = data.filter(function(element){
          return element.selected == true;
        });
        this.roles = data.filter(function(element){
          return element.selected != true;
        });
      }
    );*/
    this.rolesService.combo({}).subscribe({
      next: (data: any) => {
        var tempRoles = [];
        this.roles = data.rows;
        this.roles.forEach(role => {
          if (rolesIds.indexOf(role.id) > -1) {
            this.selected_roles.push(role);
          } else {
            tempRoles.push(role);
          }
          this.roles = tempRoles;
        });
        /*this.selected_roles = this.roles.filter(function (e) {
          return rolesIds.indexOf(e.id) > -1;
        });*/
      },
      error: (error: any) => {

      }
    });
  }

  previewHTML() {
    if (this.template.beeplugin == true) {
      this.beeService.save().then((data2: any) => {
        this.template.json = data2.jsonFile;
        this.template.html = data2.htmlFile;
        const ref = this.dialogService.open(HtmlPreviewComponent, {
          data: {
            temporal_template: this.template,
            entity_id: this.template.entity_id,
            entity_pk_id: 0
          },
          header: this.translateService.instant('component.templates.detail.previewed'),
          width: '70%',
        });
        ref.onClose.subscribe((data: any) => {
        });
      });
    } else {
      const ref = this.dialogService.open(HtmlPreviewComponent, {
        data: {
          temporal_template: this.template,
          entity_id: this.template.entity_id,
          entity_pk_id: 0
        },
        header: this.translateService.instant('component.templates.detail.previewed'),
        width: '70%',
      });
      ref.onClose.subscribe((data: any) => {
      });
    }
  }

  isDuplied(event) {
    if (this.template.id == null) {
      this.template.html = "<p></p>";
    }
    this.template.templatetype = null;
    //if (this.template.templatetype?.code != "MAIL") this.template.subject = null;
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.templatesService.isDuplied(this.template).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant('component.templates.detail.template_duplicate'),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      },
      error: (error: any) => {
        var message = "";
        for (var [key, value] of Object.entries(error.error.errors)) {
          message += value[0] + "\n\t";
        }
        if (message == "") message = error.error.title;
        this.messageService.add({ closable: false, severity: "error", detail: message });
      }
    });
  }
  duplicateTemplate() {
    //GUARDAMOS y Duplicamos la líneas de notification_to y notification_email.
    this.templatesService.save(this.template.id, this.template).subscribe({
      next: (data: any) => {

        this.templatesService.duplicateTemplate(this.template.id).subscribe({
          next: (data: any) => {
            this.cancel();

            let template_new = data;
            const ref = this.dialogService.open(TemplatesDetailComponent, {
              data: {
                id: template_new != null ? template_new.id : null,
                template_type_id: template_new.template_type_id
              },
              header: template_new != null ? this.translateService.instant('component.templates.detail.detail_entity') : this.translateService.instant('component.templates.detail.new_entity'),
              width: '70%'
            });
            ref.onClose.subscribe((data: any) => {
              this.view.fireEvent("templates.list.results", "reload");
            });
          }
        });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
      }
    })

  }

  getHtml() {
    let a = document.createElement("a");
    let dataURI = "data:text/plain;base64," + btoa(this.template.html);
    a.href = dataURI;
    a['download'] = this.template.name.replace(/\s/g, '_') + ".html";
    a.click();
    a.remove();
  }

  /*changeCode(templatetype) {
    this.template.templatetype = templatetype;
    if (templatetype.code == "DOCUMENT") this.template.subject = "";
  }
  */
  onEvent(event) {

    if (event.component == "templates.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event);
      if (event.event == "action" && event.action.name == "preview") this.previewHTML();
      if (event.event == "action" && event.action.name == "duplicate") this.duplicateTemplate();
      if (event.event == "onChange" && event.entityField?.model_property == "template_type_id") {
        //this.changeCode(this.templateTypes.filter(m => m.id == event.data.template_type_id)[0]);
      }
    }


    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.template.id) !== "undefined" ? this.translateService.instant("component.templates.detail.detail_entity", { entity_name: this.view.getEntityBaseName() }) : this.translateService.instant("component.templates.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
  }
}
