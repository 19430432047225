import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/core/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  url = "/api/contacts";

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  all(params): Observable<any> {
    if (params == null) params = {};
    if (params.type == undefined) params.type = "contact";
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  save(id, obj): Observable<object> {
    return this.http.put<object>(this.url + "/" + id, obj);
  }

  changeOrganizationAndSave(id, obj, changeOrganization = false): Observable<object> {
    return this.http.put<object>(this.url + "/" + id + "/movePut", obj, { params: { changeOrganization: changeOrganization } });
  }

  add(obj): Observable<object> {
    return this.http.post<object>(this.url, obj);
  }

  addAndLinkTask(obj): Observable<object> {
    return this.http.post<object>(this.url+"/addAndLinkTask", obj);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }
  deleteMultiple(ids): Observable<object> {
    return this.http.post<object>(this.url + "/deleteMultiple/", { contactsIds: ids });
  }

  isDuplied(obj): Observable<any> {
    return this.http.post<any>(this.url + "/isDuplied", obj);
  }

  addContactMultipleAndCreateTask(ids, task): Observable<object> {
    return this.http.post<object>("api/tasks/addContactMultipleAndCreateTask/", { contactsIds: ids, task: task });
  }
  getAllLeads(): Observable<any> {
    this.loaderService.skipRequest(this.url + "/getAllLeads", null);
    return this.http.get<any>(this.url + "/getAllLeads");
  }
}
