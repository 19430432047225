import { Component, ViewChild } from '@angular/core';
import { ViewComponent } from 'src/app/core/view/view.component';
import { CampaignsService } from '../campaigns.service';
import { ViewsService } from 'src/app/core/view/views.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent {
  @ViewChild("view") public view: ViewComponent;
  public campaignsFilters: any = {};

  constructor(
    private campaignsService: CampaignsService,
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void { }

  viewCampaign(campaign) {
    this.router.navigate(["/campaigns/" + (campaign ? campaign.id : 0)]);
  }

  deleteCampaign(campaign) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.campaigns.delete_message", { name: campaign.name }),
      header: this.translateService.instant("genera.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.campaignsService.delete(campaign.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.router.navigate(['/campaigns']);
            this.view.fireEvent("campaigns.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  onEvent(event: any) {
    if (event.component == "campaigns.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.view.fireEvent("campaigns.list.results", "reload");
    } else if (event.component == "campaigns.list.results") {
      if (event.event == "dblclick") this.viewCampaign(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewCampaign(null);
      if (event.event == "action" && event.action.name == "edit") this.viewCampaign(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteCampaign(event.data);
      if (event.event == "action" && event.action.name == "search") {
        this.view.fireEvent("campaigns.list.results", "reload", event.data);
      }
    }
  }
}
