import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from '../../organizations.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContactsService } from '../../organizations-detail/organizations-contacts-detail/contacts.service';
import { ViewComponent } from '../../../core/view/view.component';
import { TasksStatusService } from '../../../tasks/tasks-status.service';
import { TasksService } from '../../../tasks/tasks.service';
import { ViewComponentFormComponent } from '../../../core/view/component/form/view-component-form.component';
import { LoginService } from '../../../login/login.service';
import { ViewsService } from '../../../core/view/views.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organizations-quick',
  templateUrl: './organizations-quick.component.html',
  styleUrls: ['./organizations-quick.component.scss']
})
export class OrganizationsQuickComponent implements OnInit {
  @ViewChild("view1") public view1: ViewComponent;

  public checks: any = {};
  public organization: any = {};
  public task: any = {};
  public contact: any = {};

  constructor(
    private config: DynamicDialogConfig,
    private router: Router,
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private contactService: ContactsService,
    private taskService: TasksService,
    private messageService: MessageService,
    private dynamicDialogRef: DynamicDialogRef,
    private tasksStatusService: TasksStatusService,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.checks.showContact = false;
    this.checks.showTask = false;
    if (localStorage.getItem("isSuperAdmin") === 'false') {
      this.organization.responsible_id = parseInt(localStorage.getItem("userId"));
      this.task.responsible_user_id = parseInt(localStorage.getItem("userId"));
    }
    //Seleccionamos la zona si solo tiene una
    this.organization.zone_id = this.loginService.UserOnlyOneZone();

    var now = new Date();
    var nowMultiple = now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
    this.task.date = new Date(nowMultiple);
    this.task.task_action_scheduled_date = new Date(nowMultiple);
   
  }

  save() {

    this.organization.responsible = null;
    if (this.organization.vat_country_id != null) this.organization.vatCountry = null;
    if (this.organization.created_by != null) this.organization.createdBy = null;
    if (this.organization.country_id != null) this.organization.country = null;
    if (this.organization.organization_type_id != null) this.organization.organizationType = null;
    if (this.organization.parent_organization_id != null) this.organization.parent_organization = null;
    if (this.organization.zone_id != null) this.organization.zone = null;
    if (this.organization.updated_by != null) this.organization.updatedBy = null;
    if (this.organization.sector != null) this.organization.sector = null;
    if (this.organization.province != null) this.organization.province = null;

    if (!this.organization.id) {
      this.organization.name = this.organization.name_autocomplete;
      this.organization.name_autocomplete = this.organization.name;
    };

    var showContact = this.view1.getComponent("contacts.quick").isOpen();
    var showTask = this.view1.getComponent("tasks.quick").isOpen();

    if (!this.view1.getComponentForm("organizations.quick").valid ||
      //(this.checks.showContact && !this.view2.getComponentForm("contacts.quick").valid) ||
      (showContact && !this.view1.getComponentForm("contacts.quick").valid) ||
      //(this.checks.showTask && !this.view3.getComponentForm("tasks.quick").valid)
      (showTask && !this.view1.getComponentForm("tasks.quick").valid)
    ) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("component.organizations.quick.fill_mandatory") });

    } else {

      this.organizationsService.add(this.organization).subscribe({
        next: (data: any) => {
          this.organization = data;
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.quick.add_organziation_correct") });

          if (!showTask && !showContact) {
            this.cancel();
            this.router.navigate(['/organizations/', this.organization.organization_type_id ? this.organization.organization_type_id : 0, this.organization.id]);
          } else {
            if (showContact) this.saveContact();
            if (showTask && !showContact) this.saveTask();
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: "1" + error.error.title });
        }
      });
    }
  }

  saveContact() {

    this.contact.organization_id = this.organization.id;
    this.contactService.add(this.contact).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.quick.add_contact_correct") });
        this.task.contact_id = data.id;
        //if(this.checks.showTask) this.saveTask();
        var showTask = this.view1.getComponent("tasks.quick").isOpen();
        if (showTask) this.saveTask();

        this.cancel();
        this.router.navigate(['/organizations/', this.organization.organization_type_id ? this.organization.organization_type_id : 0, this.organization.id]);

      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: "error", detail: "2" + error.error.title });
      }
    });
  }

  saveTask() {
    this.task.organization_id = this.organization.id;

    //seleccionamos el estado por defecto.
    this.tasksStatusService.all({ is_default: true }).subscribe({
      next: (data: any) => {
        this.task.task_status_id = data.rows[0].id;

        this.taskService.add(this.task).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.organizations.quick.add_task_correct") });
            this.cancel();
            this.router.navigate(['/organizations/', this.organization.organization_type_id ? this.organization.organization_type_id : 0, this.organization.id]);
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: "3" + error.error.title });
          }
        });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: "4" + error.error.title });
      }
    });
  }

  cancel() {
    this.dynamicDialogRef.close();
  }
    

  onEvent(event) {
    if (event.component == "organizations.quick") {
      if (event.event == "text-autocomplete" && event.action.name == "select") {
        this.organization = event.data.name_autocomplete;
        let componentOrganization: ViewComponentFormComponent = this.view1.getComponent("organizations.quick");
        componentOrganization.setModel(this.organization);
      }
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      this.config.header = typeof (this.organization.id) !== "undefined" ?
        this.translateService.instant("component.organizations.quick.detail_entity", { entity_name: this.view1.getEntityBaseName() }) :
        this.translateService.instant("component.organizations.quick.new_entity", { entity_name: this.view1.getEntityBaseName() })

      //establecer modelos
      let componentOrganization: ViewComponentFormComponent = this.view1.getComponent("organizations.quick");
      componentOrganization.setModel(this.organization);
      let componentTask: ViewComponentFormComponent = this.view1.getComponent("tasks.quick");
      componentTask.setModel(this.task);
      let componentContact: ViewComponentFormComponent = this.view1.getComponent("contacts.quick");
      componentContact.setModel(this.contact);
    }
  }
}
