import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
registerLocaleData(localeES);

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DefaultUrlSerializer, RouterModule, UrlSerializer, UrlTree } from '@angular/router';

import { AppComponent } from './app.component';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';

import { HomeModule } from './home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './login/login.module';
import { AuthGuard } from './login/auth.guard';

import { OrganizationsModule } from './organizations/organizations.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { CalendarModule } from './calendar/calendar.module';
import { TasksModule } from './tasks/tasks.module';
import { OrganizationsTypesModule } from './configuration/organizations-types/organizations-types.module';
import { CoreModule } from './core/core.module';
import { UserCalendarModule } from './user-calendar/user-calendar.module';
import { ProductsModule } from './products/products.module';
import { TransactionTypesModule } from './configuration/transaction-types/transaction-types.module';
import { TransactionStatusModule } from './configuration/transaction-status/transaction-status.module';
import { TemplatesModule } from './configuration/templates/templates.module';
import { AttachmentsModule } from './attachments/attachments.module';
import { TransactionsModule } from './transactions/transactions.module';
import { TransactionsLinesModule } from './transactions/transactions-detail/transactions-lines/transactions-lines.module';
import { MailAccountsModule } from './configuration/mail-accounts/mail-accounts.module';
import { TrackingModule } from './tracking/tracking.module';
import { ViewsModule } from './configuration/views/views.module';
import { ZonesModule } from './configuration/zones/zones.module';
import { UsersModule } from './users/users.module';
import { MailingModule } from './mailing/mailing.module';
import { ContactsModule } from './contacts/contacts.module';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { LoginComponent } from './login/login-form/login-form.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ButtonModule } from 'primeng/button';
import { NgEventBus } from 'ng-event-bus';
import { ReportsModule } from './reports/reports.module';
import { AttachmentsTypesModule } from './configuration/attachments-types/attachments-types.module';
import { FamiliesModule } from './configuration/families/families.module';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {FieldsetModule} from 'primeng/fieldset';
import { MapsModule } from './maps/maps.module';
import { ImportModule } from './import/import.module';
import { IntegrationModule } from './configuration/integration/integration.module';
import { NotificationsModule } from './notifications/notifications.module';
import { MailMarketingModule } from './mail-marketing/mail-marketing.module';
import { UpdateModule } from './update/update.module';
import { TasksActionsTypesModule } from './configuration/tasks-actions-types/tasks-actions-types.module';
import { SectorsModule } from './configuration/sectors/sectors.module';
import { OriginsModule } from './configuration/origins/origins.module';
import { ProvincesModule } from './configuration/provinces/provinces.module';
import { EncodeHttpParamsInterceptor } from './core/EncodeHttpParamsInterceptor';
import { PreloadModule } from './preload/preload.module';
import { BusinessModule } from './configuration/business/business.module';
import { BadgeModule } from 'primeng/badge';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { LeadsModule } from './leads/leads.module';
import { EntityHistoryLineModule } from './entity-history-line/entity-history-line.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from './core/view/component/translate/CustomTranslateLoader';
import { AppSettingsService } from './app-settings.service';
import { TaskStatusModule } from './configuration/task-status/task-status.module';
import { CampaignsModule } from './campaigns/campaigns.module';

//import { ChartModule } from 'primeng/chart';

export default class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    url = url.replace(/\+/gi, '%2B');
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
  }
}
export function loadTranslations(translateService: TranslateService) {
  //cargamos ultimo idioma selecciona o el guardado
  let current_lang = localStorage.getItem("language");
  if (current_lang == null || current_lang == "") {
    current_lang = "es";
    localStorage.setItem("language", current_lang);
  }
  translateService.setDefaultLang(current_lang);
  return () => translateService.use(current_lang);
}


@NgModule({
  declarations: [		
    AppComponent
   ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, AppSettingsService]
      }
    }),

    FontAwesomeModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    FormsModule,
    CoreModule,
    LoginModule,
    PreloadModule,
    HomeModule,
    DynamicDialogModule,
    ToastModule,
    BrowserAnimationsModule,
    InputTextModule,
    ReactiveFormsModule,
    CardModule,
    ButtonModule,
    PanelModule,
    OrganizationsModule,
    OrganizationsTypesModule,
    TasksActionsTypesModule,
    ConfigurationModule,
    CalendarModule,
    UserCalendarModule,
    TasksModule,
    ProductsModule,
    TransactionTypesModule,
    TransactionStatusModule,
    TransactionsModule,
    TransactionsLinesModule,
    MailAccountsModule,
    TrackingModule,
    ZonesModule,
    CampaignsModule,
    FamiliesModule,
    UsersModule,
    TemplatesModule,
    ViewsModule,
    AttachmentsModule,
    MailingModule,
    BusinessModule,
    AttachmentsTypesModule,
    ContactsModule,
    ReportsModule,
    MailMarketingModule,
    SectorsModule,
    OriginsModule,
    //ChartModule,
    EditorModule, 
    CascadeSelectModule,
    FieldsetModule,
    UpdateModule,
    MapsModule,
    ImportModule,
    IntegrationModule,
    NotificationsModule,
    ProvincesModule,
    TaskStatusModule,
    BadgeModule,
    LeadsModule,
    EntityHistoryLineModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full', canActivate: [AuthGuard] }
    ], {onSameUrlNavigation: 'reload'})
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: LOCALE_ID, useValue: 'es-ES' }, 
    MessageService,
    DialogService,
    NgEventBus,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    { provide: APP_INITIALIZER, useFactory: loadTranslations, deps: [TranslateService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIconPacks(far);
  }
}
