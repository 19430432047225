
import { Injectable, Type } from '@angular/core';
import { Form, UntypedFormGroup } from '@angular/forms';
import { AddItemToEntityFieldListComponent } from '../../configuration/entities-detail/entities-detail-fields/add-item-to-entity-field-list/add-item-to-entity-field-list.component';
import { LoginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesService } from '../../configuration/entities.service';
import { DebugService } from '../debug.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  private model: any = null;
  private entities: any[] = [];
  constructor(
    public debugService: DebugService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private entitiesService: EntitiesService
  ) {
      this.entitiesService.all({}).subscribe(data => {
        this.entities = data.rows;
      }, error => {
      });
  }
  
  isVisible(component: any, model:any) {
    let visible = true;

    //validar si se cumple las condiciones de visibilidad
    if (component.visible_by == 'user') {
      //si es el usuario responsable
      visible = this.loginService.isUserResponsible(model);
      if (!visible && this.loginService.isUserSubordinate(model)) visible = true;
    }
    //visibilidad personalizada del componente
    if (visible) visible = this.isVisibleCustom(component, model);


    return visible;
  }

  isVisibleCustom(component: any, model: any) {

    let ret = true;
    this.model = model;
    if (component.ngIf != null) {
      let code = component.ngIf;
      ret = eval(code);
    }
    return ret;
  }

  isEditable(component: any, model: any) {
    let editable = true;
    //si hay modelo de datos cargado, revisamos validaciones de datos
    if (model.id > 0) {
      if (component != null && component.entity == "organization" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
      if (component != null && component.entity == "reports" && this.loginService.hasPermission("REPORTS_SUPERADMIN")) return true;
      if (component != null && component.entity == "users" && this.loginService.hasPermission("USERS_SUPERADMIN")) return true;
      if (component != null && component.entity == "zones" && this.loginService.hasPermission("ZONES_SUPERADMIN")) return true;

      //registros enlazados con ERP no dejamos tocar ventas
      if (component != null && component.entity == "transaction" && model.erp_id != null) return false; //transaccion enlazada con erp
      if (component != null && model.transaction != null && component.entity == "transactionLine" && model.transaction.erp_id != null) return false; //linea transaccion enlazada con erp

      if (component != null && component.entity == "transactionLine" && (this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN") || this.loginService.hasPermission("OPORTUNITIES_SUPERADMIN") || this.loginService.hasPermission("SALES_SUPERADMIN"))) return true;

      //TODO: probar si las transacciones customizadas se llaman componen.entity == transactions o el nombre
      if (component != null && component.entity == "transaction" && this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN")) return true;
      if (component != null && component.entity == "oportunity" && this.loginService.hasPermission("OPORTUNITIES_SUPERADMIN")) return true;
      if (component != null && component.entity == "sale" && this.loginService.hasPermission("SALES_SUPERADMIN")) return true
      if (component != null && component.entity == "task" && this.loginService.hasPermission("TASKS_SUPERADMIN")) return true;
      if (component != null && component.entity == "contact" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
      if (component != null && component.entity == "lead" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
      if (component != null && component.entity == "taskHistory" && this.loginService.hasPermission("TASKS_SUPERADMIN")) return true;

      //Comprobamos las transacciones customizados si tienen permisos generales.
      if (component != null && component.entity != "sales" && component.entity != "oportunity" && component.entity != "transaction" && component.code == "transaction.edit.general") {
        if (this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN")) return true;
      }
      
      //si eres responsable
      editable = this.loginService.isUserResponsible(model);

      if (!editable && this.loginService.isUserSubordinate(model)) editable = true;

      let field_editable_by = component.editable_by ?? 'all';

      if (field_editable_by == 'user' && !editable) {
        //fin
      } else if (field_editable_by == 'all' && !editable) {
        //validar por zonas, lo ven todos pero no eres reponsable
        //comprobar si es de mi zona y tengo permiso
        if (this.loginService.isUserZone(model) && this.loginService.isUserZoneWritable(component.entity)) editable = true;

        //Problema: si no tiene zona asignada, y no es SU responsable, no debería verla. (al menos no se ve en los listado de los controles.)
        if (editable == true && !this.loginService.isUserResponsible(model) && !this.loginService.isUserSubordinate(model) ) {
          var zone_id = eval("model.zone_id");
          if (zone_id == null || typeof (zone_id) === "undefined") zone_id = eval("model.organization!=null?model.organization.zone_id:null");
          if (zone_id == null || typeof (zone_id) === "undefined") {
            //Suponemos que no es el responsable y que no tiene zona asignada, si uese así no debería salir.
            editable = false;
          }
        }
      }
    }

    return editable;
  }

  isVisibleField(field:any, model:any) {
    let visible = true;
    //validar si se cumple las condiciones de visibilidad
    if (field.visible_by == 'user') {
      //si es el usuario responsable
      visible = this.loginService.isUserResponsible(model);
      if (!visible && this.loginService.isUserSubordinate(model)) visible = true;
    }
    //visibilidad personalizada del campo
    if (visible) visible = this.isVisibleFieldCustom(field, model);

    return visible;
  }

  isVisibleHeaderFieldCustom(field: any) {
    let ret = true;
    if (field.entityField != null && field.entityField.configuration != null && field.entityField.configuration.ngIf != null) {
      let code = field.entityField.configuration.ngIf;
      ret = eval(code);
    }
    return ret;
  }

  isVisibleFieldCustom(field: any, model: any) {
    let ret = true;
    this.model = model;
    
    if (field.entityField != null && field.entityField.configuration != null && field.entityField.configuration.ngIf != null) {
      let code = field.entityField.configuration.ngIf;
      try {
        
        ret = eval(code);
        this.loginService.hasFeature(42);

      } catch (e) {
        console.log(e);
        console.error("Error: " + code, field, model);
      }
    }


    return ret;
  }

  isDisabledFieldCustom(field: any, component: any) {

    let ret = false;
    if (field != null && field.entityField?.configuration != null && field.entityField.configuration.disabled != null) {
      ret = eval(field.entityField.configuration.disabled);
    }
    return ret;
  }

  isFieldReadOnly(field) {
    let ret = field.readonly;
    if (field != null && field.entityField?.configuration != null && field.entityField.configuration.readonly != null) {
      ret = field.readonly || eval(field.entityField?.configuration?.readonly);
    }
    return ret;
  }

  isEditableField(component: any, field: any, form: UntypedFormGroup, model: any, isField: boolean = false) {
    let editable = true;
      
    //si el componente no es editable
    if (!this.isEditable(component, model)) { return false; }

    //si campo de solo lectura o no modificable
   /* if (field.readonly || field.entityField.unmodifiable) {
      if (field.readonly) {
        return false;
      }
      //Si es unmodifiable, comprobamos si es un filtro, si no lo es, lo bloqueamos. sino NO
      if (component.code.indexOf('.filters') < 0) return false;
    }*/

    //también se evalua si es de solo lectura para poder porner a solo lectura fields dependiendo de una condición (no sólo true/false). Ejemplo zonas si hay erp_id
    if (this.isFieldReadOnly(field) || (!isField && field.entityField.unmodifiable)) {
      
      if (this.isFieldReadOnly(field)) {
        return false;
      }
      
      //Si es unmodifiable, comprobamos si es un filtro, si no lo es, lo bloqueamos. sino NO
      if (component.code.indexOf('.filters') < 0) return false;
    }


    //Si tienes superpermisos no se comprueba nada más.
    if (component != null && component.entity == "organization" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
    if (component != null && component.entity == "contact" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
    if (component != null && component.entity == "lead" && this.loginService.hasPermission("ORGANIZATIONS_SUPERADMIN")) return true;
    if (component != null && component.entity == "reports" && this.loginService.hasPermission("REPORTS_SUPERADMIN")) return true;
    if (component != null && component.entity == "transaction" && this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN")) return true;
    if (component != null && component.entity == "users" && this.loginService.hasPermission("USERS_SUPERADMIN")) return true;
    if (component != null && component.entity == "zones" && this.loginService.hasPermission("ZONES_SUPERADMIN")) return true;
    //TODO: probar si las transacciones customizadas se llaman componen.entity == transactions o el nombre
    if (component != null && component.entity == "oportunity" && this.loginService.hasPermission("OPORTUNITIES_SUPERADMIN")) return true;
    if (component != null && component.entity == "sale" && this.loginService.hasPermission("SALES_SUPERADMIN")) return true;
    if (component != null && component.entity == "task" && this.loginService.hasPermission("TASKS_SUPERADMIN")) return true;
    if (component != null && component.entity == "taskHistory" && this.loginService.hasPermission("TASKS_SUPERADMIN")) return true;
    if (component != null && component.entity == "transactionLine" && (this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN") || this.loginService.hasPermission("OPORTUNITIES_SUPERADMIN") || this.loginService.hasPermission("SALES_SUPERADMIN"))) return true;

    //Comprobamos las transacciones customizados si tienen permisos generales.
    if (component != null && component.entity != "sales" && component.entity != "oportunity" && component.entity != "transaction" && component.code == "transaction.edit.general") {
      if (this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN")) {
        return true;
      }
    }

    //si hay modelo de datos cargado, revisamos validaciones de datos
    if (model.id > 0) {
      //si eres responsable
      editable = this.loginService.isUserResponsible(model);
      
      if (!editable && this.loginService.isUserSubordinate(model)) editable = true;

      let field_editable_by = field.editable_by ?? 'all';
      if (field_editable_by == 'user' && !editable) {
        //fin
      } else if (field_editable_by == 'all' && !editable) {
        //validar por zonas, lo ven todos pero no eres reponsable
        //comprobar si es de mi zona y tengo permiso
        if (this.loginService.isUserZone(model) && this.loginService.isUserZoneWritable(component.entity)) editable = true;
      }
    }
    //ejecutar disable personalizado del campo
    if (editable && this.isDisabledFieldCustom(field, component)) editable = false;


    let control = (typeof (form) != "undefined" ? form.get(field.entityField.model_property) : null);
   
    if (control != null && !editable) {
      
      control.disable();
    } else if (control != null && editable) {
      control.enable();
    }
    return editable;
  }

  /**
   * Devolvemos el nombre del field.
   * 1º si tiene un label (caso de las tablas) se muestra ese nombre,
   * 2º si es customizado, el nombre customizado,
   * 3º se busca la traducción, si existe se devuelve
   * 4º la descripción del field original. 
   * @param field
   * @param entityfieldObject
   * @returns
   */
  getFieldDescription(field: any, entityfieldObject: boolean = true) {
    let label = (entityfieldObject ? field.entityField?.description : field.description);

    if (field.label && field.label != null && field.label != "") {
      label = this.translateService.instant(field.label);
    } else {
      if (field.is_custom || field.entityField?.is_custom) {
        
        //como se ha añadido la parte de traducciones customizadas mas tardes, primero buescamos si existe en la traudicción customizada
        let full_model_property = (entityfieldObject ? field.entityField?.name : field.name);
        let model_property = full_model_property.split("."); //Porque si son de otra entidad a la pintada el model property llega concatenado.
        let model_propertyToSearch = (field.entity_field_id != null ? "custom_" + model_property[model_property.length - 1] : model_property[model_property.length - 1]) ;
        let keyTranslate = (entityfieldObject ? field.entityField?.entity_code : field.entity_code) + ".entity_fields." + model_propertyToSearch;
        let label2 = this.translateService.instant(keyTranslate);
      
        if (keyTranslate != label2) {
          //No ha encontrado la clave, ponemos la descripción del campo.
          return label2;
        }
        //Ponemos si o si el nombre de la descripción porque es un field customizado
        
        return label;

      } else {
        //No es parsear la descripción sino componer la clave. EJEM: contact.entity_fields.name
        let full_model_property = (entityfieldObject ? field.entityField?.name : field.name);
        let model_property = full_model_property.split("."); //Porque si son de otra entidad a la pintada el model property llega concatenado.
        let keyTranslate = (entityfieldObject ? field.entityField?.entity_code : field.entity_code) + ".entity_fields." + model_property[model_property.length - 1];
        let label2 = this.translateService.instant(keyTranslate);
        if (keyTranslate != label2) {
          //No ha encontrado la clave, ponemos la descripción del campo.
          label = label2;
        }
      }      
    }
    return label;
  }

  /**
   * Devuelve el nombre de la entidad.
   * Como hay un cambio pero ya pueden existinr customizados antiguos, si es customizado, buscamos también si existe en translate_custom, si existe lo devolvemos sino la entidad.
   * No se puede hacer buscando directamente traducción porque no se identifica si es la traducción de la base o la traducción del customizado. así que se queda así.
   * @param entity
   * @param plural
   * @returns
   */
  getEntityName(entity: any, plural: boolean = false) {

    let label = null;
    let entityCached = this.entities.find(m => m.code == entity.code);

    //1) Nombre de la entidad customizada
    if (entityCached && entityCached.is_custom) {
     
      //Por el cambio, miramos por si acaso ya está traducido con lo nuevo. Si es así, se manda la traducción,sino la entidad customizada
      let subKey = ".name";
      if (plural) subKey = ".name_plural";
      let key = entity.code + ".entity" + subKey;
      label = this.translateService.instant(key);

      if (key == label) {
        //Si no existe, se devuelve el nombre de la entidad customizada.
        label = (!plural ? entityCached.name : entityCached.name_plural);
      }
    } else {
      //2) traducción Buscamos si está en la traducción key = "contact.entity"
      let subKey = ".name";
      if (plural) subKey = ".name_plural";
      let key = entity.code + ".entity" + subKey;
      label = this.translateService.instant(key);
      if (key == label) {
        //No tiene traduccion devolvemos la base.       //3) nombre de la entidad original
        label = (!plural ? entity.name : entity.name_plural);
      }
    }
    return label;
  }

  /**
   * Mostrará el nombre de la acción definida en la vista.
   * @param action el boton
   * @param code de la vista o del componente
   * @returns
   */
  getActionLabel(action, code) {
    let label = action.label;
    //1 buscamos en el generico general.action.add
    var key = "general.action." + action.name;
    if (this.translateService.instant(key) !== key) {
      label = this.translateService.instant(key);
    }
    //2 buscamos en el especifico
    var keySpecific = "component." + code + ".action." + action.name;
    //console.log(keySpecific);
    if (this.translateService.instant(keySpecific) !== keySpecific) {
      label = this.translateService.instant(keySpecific);
    }
    return label;
  }

  getComponentTitle(component) {
    
    let title = component.title;
    if (component.label) {
      return component.label
    }
    else
    {
      var keySpecific = "component." + component.code + ".title";
      if (this.translateService.instant(keySpecific) !== keySpecific) {
        title = this.translateService.instant(keySpecific);
      }
    }

    return title;
  }

}
