import { Component, OnInit, ViewChild } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { UsersService } from '../users/users.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MeetingsService } from '../organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { MessageService } from 'primeng/api';
import { Input, Output } from '@angular/core';
import esLocale from '@fullcalendar/core/locales/es';
import { OrganizationsMeetingsDetailComponent } from '../organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { Inject } from '@angular/core';
import { LoginService } from '../login/login.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ViewComponent } from '../core/view/view.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss']
})
export class UserCalendarComponent implements OnInit {
  @ViewChild(FullCalendarComponent) fullCalendar: FullCalendarComponent;
  @Input("user_id") public user_id: number = null;
  @Input("height") public height: any = 250;
  @Input("view") public view: ViewComponent;
  @Input("id") public id: number = null;

  public currentDate: Date = new Date();
  public user: any = null;
  public selectedUsers: number[] = [];

  public events: any[] = [];
  public from_date: Date = null;
  public to_date: Date = null;
  public tooltip_info = {
    show: false,
    description: "",
    showType: "",
    date_from: "",
    responsible: "",
    organization: "",
    duration: "",
    allDay: false,
  };
  public xPos = "0px";
  public yPos = "0px";
  public options: any = {
    locale: esLocale,
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'timeGridDay',
    defaultDate: this.currentDate,
    height: this.height,
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    },
    dateClick: (date) => {
      this.parent.meeting.from = date.date;
    },
    datesSet: (e) => {
      this.handleDatesRender(e);
    },
    eventResize: (e) => {
      this.modifyEvent(e.event._def.extendedProps, e.endDelta, "resize");
    },
    eventDrop: (e) => {
      this.modifyEvent(e.event._def.extendedProps, e.delta, "drop");
    },
    eventMouseEnter: (e) => {
      window.addEventListener("mousemove", (e) => {
        this.xPos = (e.clientX + 46) + "px";
        this.yPos = (e.clientY + 32) + "px";
      });
      this.tooltip_info.description = e.event._def.title;
      this.tooltip_info.show = true;
      this.tooltip_info.showType = e.event._def.extendedProps.showType;
      this.tooltip_info.date_from = (e.event._def.allDay != true ? e.event._def.extendedProps.date_from : "");
      this.tooltip_info.responsible = e.event._def.extendedProps.responsible;
      this.tooltip_info.organization = e.event._def.extendedProps.organization;
      this.tooltip_info.duration = (e.event._def.allDay == true ? this.translateService.instant('component.user_calendar.all_day') : this.getTimeOfEvent(e.event._def.extendedProps.minutes));
      this.tooltip_info.allDay = e.event._def.allDay;

    },
    eventMouseLeave: (e) => {
      this.tooltip_info.show = false;
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    },
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    },
    editable: true,
    selectable: true
  };
  public isSuperAdmin = "false";

  constructor(
    private meetingsService: MeetingsService,
    private usersService: UsersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private loginService: LoginService,
    private meetingService: MeetingsService,
    private translateService: TranslateService,
    @Inject(OrganizationsMeetingsDetailComponent) private parent: OrganizationsMeetingsDetailComponent
  ) {
    this.isSuperAdmin = localStorage.getItem("isSuperAdmin");
  }

  ngOnInit(): void {
    this.options.height = this.height;
    this.loadUser();
  }

  setDate(date: Date) {
    this.options.defaultDate = date;
    this.fullCalendar.getApi().gotoDate(date);
  }

  loadUser() {
    this.usersService.get(this.loginService.get("userId")).subscribe({
      next: (data: any) => {
        this.user = data;
        this.selectedUsers = this.user.metadata?.calendarConfig?.selectedUsers;
        if (this.selectedUsers == null || this.selectedUsers.length == 0) this.selectedUsers = [parseInt(localStorage.getItem("userId"))];
        this.loadMeetings();
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }

  loadMeetings() {
    let parameters: any = {
      check_invited_users: true, //busca tambien en invitados
      from_date: this.from_date.toISOString(),
      to_date: this.to_date.toISOString()
    };
    if (this.selectedUsers != null && this.selectedUsers.length > 0) {
      Object.assign(parameters, { usersIds: this.selectedUsers });
    } else {
      Object.assign(parameters, { usersIds: [parseInt(localStorage.getItem("userId"))] });
    }

    this.meetingsService.all(parameters).subscribe({
      next: (data: any) => {
        this.events = data.rows.map(m => {
          return {
            minutes: m.duration,
            title: m.subject,
            start: m.from,
            end: m.to,
            event_id: m.id,
            date_from: m.from,
            type: 'meeting',
            showType: this.translateService.instant('component.user_calendar.show_type'),
            responsible: m.responsible?.name,
            organization: m.organization?.name,
            duration: m.duration,
            backgroundColor: m.responsible.color ? m.responsible.color : "#e8e8e8",
            textColor: "black",
            allDay: m.allDay,
            editable: (m.id != this.id && ((this.isSuperAdmin === "true") || this.loginService.canEditModel(m, this.view?.entity?.code))) //Solo editamos si no es la que esta mostrando
          };
        });
      },
      error: (error: any) => this.messageService.add({ closable: false, severity: "error", detail: error.error.title })
    });
  }

  handleDatesRender($event) {
    this.from_date = $event.start;
    this.to_date = $event.end;
    this.loadMeetings();
  }

  getTimeOfEvent(duration) {
    //Éste método devuelve una cadena de texto con la duración del evento en el formato X h XX m.

    var durationText = "";
    if (duration >= 60) {
      var hours = Math.floor(duration / 60);
      var minutes = duration % 60;
      if (hours === 1) {
        //Si se necesita, se puede poner aquí "hora":
        durationText = this.translateService.instant('general.1_h_duration_text');
      } else {
        //Si se necesita, se puede poner aquí "horas":
        durationText = this.translateService.instant('general.x_h_duration_text', { hours: hours });
      }
      if (minutes > 0) {
        durationText += " ";
        if (minutes === 1) {
          //Si se necesita, se puede poner aquí "minuto":
          durationText += this.translateService.instant('general.1_m_duration_text');
        } else {
          //Si se necesita, se puede poner aquí "minutos":
          durationText += this.translateService.instant('general.x_m_duration_text', { minutes: minutes });
        }
      }
    } else {
      //Si se necesita, se puede poner aquí "minutos":
      durationText = this.translateService.instant('general.x_m_duration_text', { minutes: duration });
    }
    return durationText;
  }

  modifyEvent(props, delta, action) {
    if (props.type == "meeting") this.saveMeeting(props, delta, props.event_id, action);
  }
  saveMeeting(props, delta, meeting_id, action) {

    if (action == "drop") {
      var new_date_from = this.calcNewDate(props.date_from, delta);
      var new_date_to = this.calcNewDateTo(new_date_from, props.minutes);
      this.meetingService.setNewDates(meeting_id, { new_date_from: new_date_from, new_date_to: new_date_to, id: meeting_id }).subscribe({
        next: (data: any) => {
          //this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.user_calendar.save_event_correct') });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
    if (action == "resize") {
      var new_date_to = this.calcNewDate(props.date_to, delta);
      this.meetingService.setNewDates(meeting_id, { new_date_to: new_date_to, duration: this.calcDuration(props.date_from, new_date_to) }).subscribe({
        next: (data: any) => {
          //this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.user_calendar.save_event_correct') });
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  calcNewDate(originalDate, delta) {
    var newDate = new Date(originalDate);

    if (delta.years) {
      newDate.setFullYear(newDate.getFullYear() + delta.years);
    }
    if (delta.months) {
      newDate.setMonth(newDate.getMonth() + delta.months);
    }
    if (delta.days) {
      newDate.setDate(newDate.getDate() + delta.days);
    }
    if (delta.milliseconds) {
      newDate.setMilliseconds(newDate.getMilliseconds() + delta.milliseconds);
    }
    return newDate.toISOString();
  }

  calcNewDateTo(date, duration) {
    var dateFrom = new Date(date);
    var dateTo = new Date(dateFrom.getTime() + duration * 60000);
    return dateTo.toISOString();
  }

  calcDuration(date_from, date_to) {
    var startDate = new Date(date_from);
    var endDate = new Date(date_to);
    var duration = endDate.getTime() - startDate.getTime();
    return duration / 60000;
  }
}
