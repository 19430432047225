import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { CampaignsOrganizationsService } from '../campaigns-organizations.service';
import { CampaignsContactsService } from '../campaigns-contacts.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-add-element',
  templateUrl: './campaign-add-element.component.html',
  styleUrls: ['./campaign-add-element.component.scss']
})
export class CampaignAddElementComponent {

  public elements: any[] = [];
  public campaign_id;
  public entity;
  public rows: any[] = [];
  public results: any[] = [];
  public labels: any[] = [];
  public organizationLabels: any[] = ["organization.entity_fields.id", "organization.entity_fields.name", "organization.entity_fields.responsible_id"];
  public contactLabels: any[] = ["contact.entity_fields.id", "contact.entity_fields.fullname", "contact.entity_fields.organization_id"];
  public taskLabels: any[] = ["task.entity_fields.id", "task.entity_fields.subject", "task.entity_fields.date", "task.entity_fields.organization_id", "task.entity_fields.contact_id", "task.entity_fields.responsible_user_id", "task.entity_fields.task_status_id", "task.entity_fields.task_action_id"];
  public meetingLabels: any[] = ["meeting.entity_fields.id", "meeting.entity_fields.subject", "meeting.entity_fields.from", "meeting.entity_fields.organization_id", "meeting.entity_fields.contact_id", "meeting.entity_fields.meeting_state_id", "meeting.entity_fields.responsible_user_id"];
  public transactionLabels: any[] = ["transaction.entity_fields.id", "transaction.entity_fields.description", "transaction.entity_fields.organization_id", "transaction.entity_fields.organization_id", "transaction.entity_fields.transaction_status_id", "transaction.entity_fields.responsible_id"];
  public oportunityLabels: any[] = ["oportunity.entity_fields.id", "oportunity.entity_fields.description", "oportunity.entity_fields.date", "oportunity.entity_fields.organization_id", "oportunity.entity_fields.contact_id", "oportunity.entity_fields.transaction_status_id", "oportunity.entity_fields.responsible_id"];
  public saleLabels: any[] = ["sale.entity_fields.id", "sale.entity_fields.description", "sale.entity_fields.date", "sale.entity_fields.organization_id", "sale.entity_fields.organization_id", "sale.entity_fields.transaction_status_id", "sale.entity_fields.responsible_id"];
  public notificationEmailsLabels: any[] = ["notificationsEmails.entity_fields.id", "notificationsEmails.entity_fields.subject", "notificationsEmails.entity_fields.from", "notificationsEmails.entity_fields.date", "notificationsEmails.entity_fields.status"];
  public selected: any[] = [];
  public filters: any = {};
  public is_valid: boolean = false;

  public add_organizations_checkbox: boolean;
  public add_contacts_checkbox: boolean;
  public visible_organizations_checkbox: boolean;
  public visible_contacts_checkbox: boolean;

  constructor(
    private organizationsService: OrganizationsService,
    private contactsService: ContactsService,
    private campaignsOrganizationsService: CampaignsOrganizationsService,
    private campaignsContactsService: CampaignsContactsService,
    private tasksService: TasksService,
    private meetingsService: MeetingsService,
    private transactionsService: TransactionsService,
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit() {
    this.campaign_id = this.config.data.campaign_id;
    this.entity = this.config.data.entity;
    this.revealCheckboxes();
    this.loadElements();
  }

  loadElements() {
    switch (this.entity) {
      case "organization":
        this.labels = this.organizationLabels;
        if (this.is_valid) this.loadOrganizations();
        break;
      case "contact":
        this.labels = this.contactLabels;
        if (this.is_valid) this.loadContacts();
        break;
      case "task":
        this.labels = this.taskLabels;
        if (this.is_valid) this.loadTasks();
        break;
      case "meeting":
        this.labels = this.meetingLabels;
        if (this.is_valid) this.loadMeetings();
        break;
      case "transaction":
        this.labels = this.transactionLabels;
        if (this.is_valid) this.loadTransactions();
        break;
      case "oportunity":
        this.labels = this.oportunityLabels;
        //this.loadTransactions();
        if (this.is_valid) this.loadOportunities();
        break;
      case "sale":
        this.labels = this.saleLabels;
        //this.loadTransactions();
        if (this.is_valid) this.loadSales();
        break;
      case "notificationEmails":
        this.labels = this.notificationEmailsLabels;
        if (this.is_valid) this.loadNotifications();
        break;
      default:
        break;
    }
  }

  loadOrganizations() {
    this.organizationsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadContacts() {
    this.contactsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadTasks() {
    this.tasksService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadMeetings() {
    this.meetingsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadTransactions() {
    this.transactionsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }

  loadOportunities() {
    this.filters.transaction_type_id = 1;
    this.transactionsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    })
  }
  loadSales() {
    this.filters.transaction_type_id = 2;
    this.transactionsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    })
  }

  loadNotifications() {
    this.notificationsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.rows = data.rows;
      },
      error: (error: any) => {

      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  saveCampaign() {
    switch (this.entity) {
      case "contact":
        this.saveCampaignContacts();
        break;
      case "organization":
        this.saveCampaignOrganizations();
        break;
      case "task":
        this.saveTasksToCampaign();
        break;
      case "oportunity":
        this.saveTransactionsToCampaign();
        break;
      case "sale":
        this.saveTransactionsToCampaign();
        break;
      case "transaction":
        this.saveTransactionsToCampaign();
        break;
      case "meeting":
        this.saveMeetingsToCampaign();
        break;
      case "notificationEmails":
        this.saveNotificationsToCampaign();
        break;
      default:
        break;
    }
  }
  saveTasksToCampaign() {
    this.tasksService.addMultiplesToCampaign({ campaign_id: this.campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveTransactionsToCampaign() {
    this.transactionsService.addMultiplesToCampaign({ campaign_id: this.campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveMeetingsToCampaign() {
    this.meetingsService.addMultiplesToCampaign({ campaign_id: this.campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveNotificationsToCampaign() {
    this.notificationsService.addMultiplesToCampaign({ campaign_id: this.campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveCampaignOrganizations() {
    this.campaignsOrganizationsService.addMultiples({ campaign_id: this.campaign_id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveCampaignContacts() {
    this.campaignsContactsService.addMultiples({ campaign_id: this.campaign_id, ids: this.selected, add_organizations_checkbox: this.add_organizations_checkbox, add_contacts_checkbox: this.add_contacts_checkbox }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_campaign") });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  validateFilters(): boolean {
    for (const key in this.filters) {
      if (this.filters[key].length >= 3) {
        this.is_valid = true;
        return true;
      }
    }
    this.is_valid = false;
    return false;
  }

  onInputChange(): void {
    this.is_valid = this.validateFilters();
  }

  revealCheckboxes() {
    switch (this.entity) {
      case "contact":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = true;
        break;
      case "organization":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      case "task":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "oportunity":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "sale":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "transaction":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "meeting":
        this.visible_contacts_checkbox = true;
        this.visible_organizations_checkbox = true;
        break;
      case "notifications":
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
      default:
        this.visible_contacts_checkbox = false;
        this.visible_organizations_checkbox = false;
        break;
    }
  }
}
