import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { TemplatesService } from 'src/app/configuration/templates/templates.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { MailingAttachmentsComponent } from 'src/app/mailing/mailing-attachments/mailing-attachments.component';
import { UserMailAccountsService } from 'src/app/users/users-mail-accounts-detail/user-mail-accounts.service';
import { CoreDialogService } from '../../../core/dialogs/core-dialog.service';
import { ReportsService } from '../../reports.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CampaignsService } from 'src/app/campaigns/campaigns.service';

@Component({
  selector: 'app-report-mail-marketing',
  templateUrl: './report-mail-marketing.component.html',
  styleUrls: ['./report-mail-marketing.component.scss']
})
export class ReportMailMarketingComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public templates: any[] = [];
  public campaigns: any[] = [];
  public entity_id: any;
  public entity_pk_id: any;
  public email = {
    campaign_id: null,
    // from: null,
    mail_account_id: null,
    to: [],
    ccc: [],
    bcc: [],
    tos: "",
    cccs: "",
    bccs: "",
    subject: "",
    template_id: null,
    body: "",
    schedule: "now",
    //date : new Date(),
    files: [],
    mailchimp_sync: null,
    mailjet_sync: null
  }
  public selectedMailAccount: any = null;
  public emails: any = [];
  public results: any = [];
  public resultsCorrects: any = [];
  public totalRows: number = 0;
  public rows: any = Array<any>();
  public entity: any = {};
  public reportId: number;
  public selected: any[] = [];
  public schedules: any[] = [];
  public tinyMceConfig: any;

  public page: number;
  public totalPages: number;
  public prevTotalRows: number;

  constructor(private route: ActivatedRoute,
    private templatesService: TemplatesService,
    private campaignsService: CampaignsService,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private userMailAccountsService: UserMailAccountsService,
    private dynamicDialogRef: DynamicDialogRef,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private attachmentsService: AttachmentsService,
    private router: Router,
    private dialogService: DialogService,
    private viewsService: ViewsService,
    private coreDialogService: CoreDialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.schedules = [{ label: this.translateService.instant("component.reports.detail.send_now"), value: "now" }, { label: this.translateService.instant("component.reports.detail.select_datetime"), value: "customdate" }]
    this.entity_id = this.config.data.entity_id;
    this.entity_pk_id = this.config.data.entity_pk_id;
    this.page = this.config.data.page;
    this.totalPages = this.config.data.totalPages;
    this.prevTotalRows = this.config.data.totalRows;

    this.loadTemplates();
    this.loadCampaigns();
    this.loadEmails();
    this.configureTinyMce();
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.reportId, this.config.data.reportResults, this.config.data.entity, this.config.data.entity_id, this.config.data.entity_pk_id);
    });
  }
  configureTinyMce() {
    const that = this;
    this.tinyMceConfig =
    {
      promotion: false,
      height: 500,
      menubar: true,
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      toolbar_mode: 'wrap',
      language: 'es',
      language_url: '/assets/tinymce/langs/es.js',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      file_picker_types: 'image',
      paste_data_images: false,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templatesService.UploadImage(datafile).subscribe({
              next: (data: any) => {
                cb(data.location);
              },
              error: (error: any) => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    };
  }

  loadData(reportId: number, results: any, entity: any, entity_id: number, entity_pk_id: number) {
    this.reportId = reportId;
    this.results = results;
    this.entity = entity;
    this.entity_id = entity_id;
    this.entity_pk_id = entity_pk_id;
    if (this.prevTotalRows > 500) {
      this.selected = this.results.baseIds;
    } else {
      this.selected = this.results.values.map(m => m.base_id + "");
    }
    this.reportsService.GetContactsAllowedComunicationFromReport(this.selected, this.reportId).subscribe({
      next: (res: any) => {
        this.resultsCorrects = res;
        this.selected = this.resultsCorrects.map(m => m + "");
        this.rows = this.results.values.filter(m => this.selected.includes(m.base_id + ""));
        this.totalRows = this.rows.length;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadTemplates() {
    this.templatesService.all({ template_type_id: 1, entity_id: this.entity_id }).subscribe({
      next: (data: any) => {
        this.templates = data.rows;
        this.templates.unshift({ name: this.translateService.instant("component.reports.detail.none") });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadCampaigns() {
    this.campaignsService.all({}).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
        this.campaigns.unshift({ name: this.translateService.instant("component.reports.detail.none") });
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  loadEmails() {
    this.userMailAccountsService.all({ user_id: localStorage.getItem("userId") }).subscribe({
      next: (data: any) => {
        this.emails = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  loadTemplateValue(event) {
    //Esto carga el HTML de la plantilla en el editor:
    if (event.value) {
      this.templatesService.get(event.value).subscribe({
        next: (data: any) => {
          this.email.body = data.html;
          if (data.subject != null && data.subject != "") {
            this.email.subject = data.subject;
          }
          else {
            this.email.subject = data.name;
          }
        }
      });
    } else {
      this.email.body = "";
    }
  }

  sendMails() {
    //if(this.email.from == null) this.email.from = this.emails[0];

    this.reportsService.sendEmailMarketing(this.email, this.selected, this.reportId).subscribe({
      next: (res: any) => {
        //preguntar
        if (res.success) {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.detail.mail_save_to_send") });
          this.dynamicDialogRef.close("success");
          this.router.navigate(['/mailMarketing']);
        } else {
          if (res.error == "mailchimp_sync_required") {
            //preguntamos y enviamos
            this.coreDialogService.confirm({
              message: this.translateService.instant("component.reports.detail.mailchimp_problem"),
              accept: () => {
                //respuesta que si
                this.email.mailchimp_sync = "mark_to_sync";
                this.sendMails();
              },
              reject: () => {
                //respuesta que no, preguntar si continuar omitiendolos
                this.coreDialogService.confirm({
                  message: this.translateService.instant("component.reports.detail.mailchimp_syncronize"),
                  accept: () => {
                    //respuesta que si
                    this.email.mailchimp_sync = "skip";
                    this.sendMails();
                  },
                  reject: () => {
                    //no enviamos
                    this.email.mailchimp_sync = "";
                  }
                })
              }
            });
          } else if (res.error == "mailjet_sync_required") {
            //preguntamos y enviamos
            this.coreDialogService.confirm({
              message: this.translateService.instant("component.reports.detail.mailjet_problem"),
              accept: () => {
                //respuesta que si
                this.email.mailjet_sync = "mark_to_sync";
                this.sendMails();
              },
              reject: () => {
                //respuesta que no, preguntar si continuar omitiendolos
                this.coreDialogService.confirm({
                  message: this.translateService.instant("component.reports.detail.mailjet_syncronize"),
                  accept: () => {
                    //respuesta que si
                    this.email.mailjet_sync = "skip";
                    this.sendMails();
                  },
                  reject: () => {
                    //no enviamos
                    this.email.mailjet_sync = "";
                  }
                })
              }
            });
          } else {
            this.dynamicDialogRef.close();
          }
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  getRowValue(header, value = "", item) {

    if (typeof (this.entity.fields) !== "undefined") {

      let field = this.entity.fields.find(m => m.id == header.entity_field_id);
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") property = field.model_property_label;

      let formatFn = field.formatFn;
      if (formatFn == null && field.configuration != null && field.configuration.formatFn != null) {
        formatFn = field.configuration.formatFn;
      }
      if (formatFn != null) {
        var format = formatFn.split("|");
        if (format[0] == "date") value = this.formatDate(value, format);
        if (format[0] == "suffix") value = this.formatSuffix(value, format);
        if (format[0] == "phone") value = this.formatPhone(value, format);
        if (format[0] == "mail") value = this.formatMail(value, format);
        if (format[0] == "www") value = this.formatWWW(value, format);
        //if (format[0] == "badge") value = this.formatBadge(field, value, format);
        if (format[0] == "image") value = this.formatImage(field, format);
        if (format[0] == "currency") value = this.formatCurrency(value, format);
        if (format[0] == "number") value = this.formatNumber(value, format);
      }
      if (String(value) === "false") value = "No";
      if (String(value) === "true") value = "Si";
      if (value == null || value == "") value = " ";
    }
    return value;
  }

  formatDate(value: any, args: any[]) {
    return this.datePipe.transform(value, args[1]);
  }
  formatSuffix(value: any, args: any[]) {
    return value + args[1];
  }
  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = "<a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatWWW(value: any, args: any[]) {
    if (value == null) return "";
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    var formattedValue = "<a href='" + value + "' target='_blank'>" + value + "</a>";
    return formattedValue;
  }
  formatBadge(field: any, value: any, args: any[]) {
    //TODO: Falta revisar este.
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("row." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.error(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      var formattedValue = "<div class='" + className + "'>" + value + "</div>";
    }
    return formattedValue;
  }
  formatImage(value: any, args: any[]) {
    if (value == null) return "";
    var formattedValue = `<div class="d-flex justify-content-left align-items-center"><div class="avatar-wrapper"><div class="avatar  me-1"><img src="../../../app-assets/images/avatars/2-small.png" alt="Avatar" height="32" width="32"></div></div><div class="d-flex flex-column"><a href="app-user-view.html" class="user_name text-truncate"><span class="fw-bold">Zsazsa McCleverty</span></a><small class="emp_post text-muted">@zmcclevertye</small></div></div>`;
    return formattedValue;
  }
  formatCurrency(value: any, args: any[]) {
    if (value == null) return "0 €";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale, { style: "currency", currency: environment.companyConfiguration.currency, useGrouping: true }).format(value);
    return formattedValue;
  }
  formatNumber(value: any, args: any[]) {
    if (value == null) return "0";
    var formattedValue = new Intl.NumberFormat(environment.companyConfiguration.locale).format(value)
    return formattedValue;
  }

  onBasicUpload(event) {
    let i = 0;
    let guids = [];
    if (event.files.length > 1) guids = event.originalEvent.body.guids;
    else if (event.files.length == 1) guids = [event.originalEvent.body.guid];
    for (let f of event.files) {
      var file = { filename: "", mimetype: "", guid: "", size: "" };
      file.filename = f.name;
      file.mimetype = f.type;
      file.guid = guids[i];
      file.size = f.size;
      this.email.files.push(file);
      i++;
    }
  }
  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.reports.detail.error_updated_file") });
  }
  deleteAttachment(guid, index) {
    this.email.files.splice(index, 1);
  }

  uploadAxial() {
    const ref = this.dialogService.open(MailingAttachmentsComponent, {
      data: {
        entity_id: this.entity_id,
        entity_pk_id: this.entity_pk_id,
        organization_id: null,
        is_generic: true,
      },
      header: this.translateService.instant("component.reports.detail.add_document"),
      width: "50%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      if (data != null) {
        for (let f of data) {
          this.email.files.push(f);
        }
      }
    });
  }

  onMailAccountChange() {
    this.selectedMailAccount = this.emails.find(m => m.mailAccount_id == this.email.mail_account_id);
  }

}
